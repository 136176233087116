import {
  buildBasePayload,
  buildFetchWithdrawHistoryPayload,
} from "../../utils/PayloadUtils";
import { callapi, callListApi } from "../../utils/APIUtils";

export const fetchWithdrawHistories = async (
  page,
  pageSize,
  search,
  sort = "D",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    buildFetchWithdrawHistoryPayload,
  );
  const response = await callListApi(
    "/api/admin/search/list/withdrawHistory",
    "POST",
    req,
  )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  const data2 = await response;

  const data = {
    count: data2.page.totalElements,
    results: data2.data,
  };
  return data;
};

export const fetchMarketingWithdrawHistories = async (
  page,
  pageSize,
  search,
  sort = "D",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    buildFetchWithdrawHistoryPayload,
  );
  const response = await callListApi(
    "/api/admin/marketing/search/list/withdrawHistory",
    "POST",
    req,
  )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  const data2 = await response;

  const data = {
    count: data2.page.totalElements,
    results: data2.data,
  };
  return data;
};

export const createBlockAutoWithdrawTransaction = (payload) => {
  return callapi(
    "/api/admin/withdrawHistory/updateBlockAutoTransaction",
    "POST",
    payload,
  );
};

export const updateWithdrawTransactionStatus = (payload) => {
  return callapi("/api/admin/withdrawHistory/updateStatus", "PUT", payload);
};
