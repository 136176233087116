import {
  Box,
  Flex,
  Grid,
  Text,
  Icon,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import {
  HiOutlineUserAdd,
  HiOutlineUserRemove,
  HiOutlineUserGroup,
  HiOutlinePresentationChartLine,
  HiOutlineXCircle,
  HiOutlineDocumentSearch,
  HiOutlineClipboardCheck,
  HiOutlineCurrencyDollar,
  HiOutlineDocumentAdd,
} from "react-icons/hi";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import moment from "moment";
import ReportsCard from "./components/ReportsCard";

import {
  dashboardLastDeposit,
  dashboardLastWithdraw,
} from "variables/columns/dashboard/dashboardColumnsData";
import SimpleTable from "components/Tables/SimpleTable";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import LineChart from "components/Charts/LineChart";
import TopTransactionCard from "./components/TopTransactionCard";
import {
  fetchDashboardMarketingSummary,
  fetchDashboardSummary,
  fetchLast20DepositHistory,
  fetchLast20WithdrawHistory,
  fetchProfitGraph,
  fetchTopTransaction,
} from "services/dashboard/dashboardService";
import { getReferenceDates } from "../../utils/DateformatUtils";
import { createLineChartOptions } from "utils/ChartUtils";
import { HiOutlineDocumentMinus } from "react-icons/hi2";
import { ERROR_MESSAGE } from "../../variables/system";
import { useErrorHandler } from "../../hooks/useErrorHandler";

const Dashboard = ({ pageName }) => {
  const { dateNow, dateDayCheck } = getReferenceDates();

  const [selectedDates, setSelectedDates] = useState([dateDayCheck, dateNow]);

  const [summary, setSummary] = useState({
    deposit: {
      amount: "0.00",
      count: "0",
    },
    depositMistake: {
      amount: "0.00",
      count: "0",
    },
    depositWaitToApprove: {
      amount: "0.00",
      count: "0",
    },
    withdraw: {
      amount: "0.00",
      count: "0",
    },
    withdrawMistake: {
      amount: "0.00",
      count: "0",
    },
    withdrawWaitToApprove: {
      amount: "0.00",
      count: "0",
    },
    depositBonus: {
      amount: "0.00",
      count: "0",
    },
    profit: {
      amount: "0.00",
      count: "0",
    },
    newRegister: {
      amount: "0.00",
      count: "0",
    },
    newRegisterNoDeposit: {
      amount: "0.00",
      count: "0",
    },
    countDeposit: {
      amount: "0.00",
      count: "0",
    },
    countWithdraw: {
      amount: "0.00",
      count: "0",
    },
    remainBalance: {
      amount: "0.00",
      count: "0",
    },
  });

  const [lastDeposit, setLastDeposit] = useState([]);
  const [lastWithdraw, setLastWithdraw] = useState([]);

  const [memberLineData, setMemberLineData] = useState([]);

  const [topDeposit, setTopDeposit] = useState([]);
  const [topWithdraw, setTopWithdraw] = useState([]);
  const [chartOptions, setChartOptions] = useState({});

  const role = JSON.parse(localStorage.getItem("APP_PROFILE")).role;

  const handleError = useErrorHandler();

  const handleShowTopTransaction = () => {
    setTopDeposit([]);

    let reqDeposit = {
      listDateFrom: moment(selectedDates[0]).format("yyyy-MM-DD HH:mm:ss"),
      listDateTo: moment(selectedDates[1]).format("yyyy-MM-DD HH:mm:ss"),
      orderBy: "deposit",
    };
    fetchTopTransaction(reqDeposit).then((res) => {
      if (res.status === 200) {
        setTopDeposit(res.data.data);
      }
    });

    setTopWithdraw([]);

    let reqWithdraw = {
      listDateFrom: moment(selectedDates[0]).format("yyyy-MM-DD HH:mm:ss"),
      listDateTo: moment(selectedDates[1]).format("yyyy-MM-DD HH:mm:ss"),
      orderBy: "withdraw",
    };

    fetchTopTransaction(reqWithdraw).then((res) => {
      if (res.status === 200) {
        setTopWithdraw(res.data.data);
      }
    });
  };

  const searchChange = () => {
    searchDashboard();
    searchLastTransaction();
    searchGraph();
    handleShowTopTransaction();
  };
  const searchLastTransaction = () => {
    fetchLast20DepositHistory().then((res) => {
      if (res.status === 200) {
        let arr = res.data.data;
        arr.forEach((item, i) => {
          item.id = i + 1;
        });
        setLastDeposit(arr);
      }
    });
    fetchLast20WithdrawHistory().then((res) => {
      if (res.status === 200) {
        setLastWithdraw(res.data.data);
      }
    });
  };

  const searchGraph = () => {
    setMemberLineData([]);

    let req = {
      value: moment(selectedDates[0]).format("yyyy-MM"),
    };

    fetchProfitGraph(req).then((res) => {
      if (res.status === 200) {
        const newChartOptions = createLineChartOptions({
          xAxisCategories: res.data.data.labelsWithdraw,
        });
        setChartOptions(newChartOptions);
        setMemberLineData([
          {
            name: "Deposit",
            data: res.data.data.dataDeposit,
          },
          {
            name: "Withdraw",
            data: res.data.data.dataWithdraw,
          },
        ]);
      }
    });
  };

  const searchDashboard = () => {
    const req = {
      startDate: moment(selectedDates[0]).format("yyyy-MM-DD HH:mm:ss"),
      endDate: moment(selectedDates[1]).format("yyyy-MM-DD HH:mm:ss"),
    };

    const response =
      role !== "MARKETING"
        ? fetchDashboardSummary(req)
        : fetchDashboardMarketingSummary(req);

    response
      .then((res) => {
        if (res.status === 200) {
          setSummary(res.data.data);
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    searchDashboard();
    searchLastTransaction();
    searchGraph();
    handleShowTopTransaction();
  }, []);

  return (
    <Flex flexDirection="column" pt={{ base: "65px", md: "75px" }}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="lg" fontWeight="bold">
          {pageName}
        </Text>
        <Box>
          <CustomRangePicker
            selectedDates={selectedDates}
            onDateChange={(v) => {
              setSelectedDates(v);
              if (v.length == 2) {
                searchChange();
              }
            }}
          />
        </Box>
      </Flex>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(5, 1fr)" }}
        gap="24px"
        mb="24px"
        pt={{ base: "20px", md: "25px" }}
      >
        <ReportsCard
          title={"ยอดฝาก"}
          number={summary.deposit.amount}
          icon={
            <Icon
              as={HiOutlineDocumentAdd}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          description={summary.deposit.count + " รายการ"}
          isInteger={false}
        />
        <ReportsCard
          title={"ฝาก (อนุมัติ)"}
          number={summary.depositMistake?.amount}
          icon={
            <Icon
              as={HiOutlineClipboardCheck}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          description={summary.depositMistake?.count + " รายการ"}
          isInteger={false}
        />
        <ReportsCard
          title={"ฝาก (รอการตรวจสอบ)"}
          icon={
            <Icon
              as={HiOutlineDocumentSearch}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          number={summary.depositWaitToApprove?.amount}
          description={summary.depositWaitToApprove?.count + " รายการ"}
          isInteger={false}
        />
        <ReportsCard
          title={"ยอดถอน"}
          icon={
            <Icon
              as={HiOutlineDocumentMinus}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          number={summary.withdraw?.amount}
          description={summary.withdraw?.count + " รายการ"}
          isInteger={false}
        />
        <ReportsCard
          title={"ถอน (อนุมัติ)"}
          icon={
            <Icon
              as={HiOutlineClipboardCheck}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          number={summary.withdrawMistake?.amount}
          description={summary.withdrawMistake?.count + " รายการ"}
          isInteger={false}
        />
        <ReportsCard
          title={"ถอน (รอการตรวจสอบ)"}
          icon={
            <Icon
              as={HiOutlineDocumentSearch}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          number={summary.withdrawWaitToApprove?.amount}
          description={summary.withdrawWaitToApprove?.count + " รายการ"}
          isInteger={false}
        />
        <ReportsCard
          title={"ยอดฝากโบนัส"}
          icon={
            <Icon
              as={HiOutlineCurrencyDollar}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          number={summary.depositBonus?.amount}
          description={summary.depositBonus?.count + " รายการ"}
          isInteger={false}
        />
        <ReportsCard
          title={"กำไร"}
          icon={
            <Icon
              as={HiOutlinePresentationChartLine}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          number={summary.profit?.amount}
          description={summary.profit?.count + " รายการ"}
          isInteger={false}
        />
        <ReportsCard
          title={"สมัครใหม่"}
          number={summary.newRegister.count}
          icon={
            <Icon
              as={HiOutlineUserGroup}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          description={"สมาชิกสมัครใหม่"}
          isInteger={true}
        />
        <ReportsCard
          title={"สมัครไม่ฝาก"}
          icon={
            <Icon
              as={HiOutlineXCircle}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          number={summary.newRegisterNoDeposit?.count}
          description={"สมาชิกที่สมัครใหม่แต่ไม่ฝาก"}
          isInteger={true}
        />
        <ReportsCard
          title={"จำนวนคนฝาก"}
          number={summary.countDeposit?.count}
          icon={
            <Icon
              as={HiOutlineUserAdd}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          description={"ไม่นับกรณีคนฝากรอบสอง"}
          isInteger={true}
        />
        <ReportsCard
          title={"จำนวนคนถอน"}
          icon={
            <Icon
              as={HiOutlineUserRemove}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          number={summary.countWithdraw?.count}
          description={"ไม่นับกรณีคนถอนรอบสอง"}
          isInteger={true}
        />
      </Grid>
      {role !== "MARKETING" && (
        <Grid
          templateColumns={{ sm: "1fr", lg: "1.8fr 1.2fr" }}
          gap="24px"
          mb="26px"
          minH="475px"
        >
          <Card px="0px" pb="0px" h="100%">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Text fontSize="lg" fontWeight="bold">
                รายงาน ฝาก/ถอน
              </Text>
            </CardHeader>
            <CardBody h="100%">
              <Box w="100%" h="100%">
                {memberLineData.length > 0 ? (
                  <LineChart
                    chartData={memberLineData}
                    chartOptions={chartOptions}
                  />
                ) : null}
              </Box>
            </CardBody>
          </Card>
          <Tabs isFitted variant="enclosed">
            <TabList>
              <Tab
                _selected={{ color: "green.500" }}
                fontSize={{ base: "sm", md: "md" }}
                _focus={{ boxShadow: "none" }}
              >
                Top 5 ยอดฝากสูงสุด
              </Tab>
              <Tab
                _selected={{ color: "green.500" }}
                fontSize={{ base: "sm", md: "md" }}
                _focus={{ boxShadow: "none" }}
              >
                Top 5 ยอดถอนสูงสุด
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel pl={0} pr={0}>
                {topDeposit.slice(0, 5).map((data, index) => (
                  <Box key={index} mt={index === 0 ? 0 : 4}>
                    <TopTransactionCard
                      key={index}
                      bankCode={data.bankCode}
                      username={data.username}
                      phoneNumber={data.mobileNo}
                      number={data.deposit}
                      isDeposit={true}
                    />
                  </Box>
                ))}
              </TabPanel>
              <TabPanel pl={0} pr={0}>
                {topWithdraw.slice(0, 5).map((data, index) => (
                  <Box key={index} mt={index === 0 ? 0 : 4}>
                    <TopTransactionCard
                      key={index}
                      bankCode={data.bankCode}
                      username={data.username}
                      phoneNumber={data.mobileNo}
                      number={data.withdraw}
                      isDeposit={false}
                    />
                  </Box>
                ))}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Grid>
      )}
      {role !== "MARKETING" && (
        <Grid templateColumns={{ md: "repeat(2, 1fr)" }} gap="24px">
          <Card px="0px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" mb="6px">
                  รายการฝากล่าสุด
                </Text>
              </Flex>
            </CardHeader>
            <CardBody h="100%">
              <Box w="100%" h="100%">
                {lastDeposit.length > 0 ? (
                  <SimpleTable
                    tableData={lastDeposit}
                    columnsData={dashboardLastDeposit}
                    rowSize={10}
                  />
                ) : null}
              </Box>
            </CardBody>
          </Card>

          <Card px="0px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" mb="6px">
                  รายการถอนล่าสุด
                </Text>
              </Flex>
            </CardHeader>
            <CardBody h="100%">
              <Box w="100%" h="100%">
                {lastWithdraw.length > 0 ? (
                  <SimpleTable
                    tableData={lastWithdraw}
                    columnsData={dashboardLastWithdraw}
                    rowSize={10}
                  />
                ) : null}
              </Box>
            </CardBody>
          </Card>
        </Grid>
      )}
    </Flex>
  );
};

export default Dashboard;
