import React, { useEffect, useState } from "react";
import { useErrorHandler } from "hooks/useErrorHandler";
import { ERROR_MESSAGE } from "variables/system";
import {
  Box,
  Flex,
  Grid,
  Icon,
  Spacer,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import SearchTable1 from "components/Tables/SearchTable1";
import { fetchStaffCreditHistoryReport } from "services/website-report/staffCreditHistoryReportService";
import { staffCreditHistoryColumnsData } from "variables/columns/website-report/staffCreditHistoryColumnsData";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";

import { getReferenceDates } from "../../../utils/DateformatUtils";
import moment from "moment";
import ReportsCard from "../../dashboard/components/ReportsCard";
import { HiOutlineCash, HiOutlineReceiptRefund } from "react-icons/hi";

const StaffCreditReport = ({ pageName }) => {
  const { dateNow, dateDayCheck } = getReferenceDates();
  const [search, setSearch] = useState({
    startDate: moment(dateDayCheck).format("DD/MM/yyyy HH:mm:ss"),
    endDate: moment(dateNow).format("DD/MM/yyyy HH:mm:ss"),
    type: "ALL",
  });

  const [selectedDates, setSelectedDates] = useState([dateDayCheck, dateNow]);

  const handleDateChange = (v) => {
    setSelectedDates(v);
    if (v.length === 2) {
      const startDate = moment(v[0]).startOf("day");
      const endDate = moment(v[1]).endOf("day");

      setSearch({
        ...search,
        startDate: startDate.format("DD/MM/yyyy HH:mm:ss"),
        endDate: endDate.format("DD/MM/yyyy HH:mm:ss"),
      });
    } else {
      setSearch({
        ...search,
        startDate: moment(v[0]).startOf("day").format("DD/MM/yyyy HH:mm:ss"),
      });
    }
    reloadData();
  };

  const [staffCreditHistoryData, setStaffCreditHistoryData] = useState([]);
  const [staffCreditHistorySummaryData, setStaffCreditHistorySummaryData] =
    useState({
      noSlip: 0,
      cutCredit: 0,
      addCredit: 0,
    });

  const searchOptions = [
    {
      label: "ทั้งหมด",
      value: "ALL",
    },
    {
      label: "ลบเครดิต",
      value: "CUT_CREDIT",
    },
    {
      label: "สลิปไม่แสดง",
      value: "NO_SLIP",
    },
    {
      label: "เพิ่มเครดิต",
      value: "ADD_CREDIT",
    },
  ];

  const [isLoading, setIsLoading] = useState(true);

  const handleError = useErrorHandler();

  const handleOptionChange = (selectedOption) => {
    // setSelectedMarketingId(selectedOption ? selectedOption.value : null);
    setSearch({
      ...search,
      type: selectedOption ? selectedOption.value : null,
    });
  };
  const reloadData = () => {
    setIsLoading(true);
    fetchStaffCreditHistoryReport(search)
      .then((response) => {
        setStaffCreditHistoryData(response.data.data.list);
        setStaffCreditHistorySummaryData(response.data.data.summary);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error, ERROR_MESSAGE);
      });
  };
  useEffect(() => {
    reloadData();
  }, [search, setSearch]);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Box>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Box>
        </AnimatedFlex>
        <Grid
          templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
          gap="24px"
          mb="24px"
          pt={{ base: "20px", md: "25px" }}
        >
          <ReportsCard
            title={"เพิ่มเครดิต"}
            icon={
              <Icon
                as={HiOutlineCash}
                w={{ base: "20px", md: "30px" }}
                h={{ base: "20px", md: "30px" }}
                color="black"
              />
            }
            number={staffCreditHistorySummaryData.addCredit.toString()}
            isInteger={false}
          />
          <ReportsCard
            title={"สลิปไม่แสดง"}
            number={staffCreditHistorySummaryData.noSlip.toString()}
            icon={
              <Icon
                as={HiOutlineCash}
                w={{ base: "20px", md: "30px" }}
                h={{ base: "20px", md: "30px" }}
                color="black"
              />
            }
            isInteger={false}
          />
          <ReportsCard
            title={"ลบเครดิต"}
            number={staffCreditHistorySummaryData.cutCredit.toString()}
            icon={
              <Icon
                as={HiOutlineReceiptRefund}
                w={{ base: "20px", md: "30px" }}
                h={{ base: "20px", md: "30px" }}
                color="black"
              />
            }
            isInteger={false}
          />
        </Grid>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  {pageName}
                </Text>
              </Flex>
              <Spacer />
              <Box width="235px">
                <SearchableDropdown
                  options={searchOptions}
                  isSearchable={false}
                  onChange={handleOptionChange}
                />
              </Box>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <SearchTable1
                  tableData={staffCreditHistoryData}
                  columnsData={staffCreditHistoryColumnsData}
                  hideSearch={false}
                />
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default StaffCreditReport;
