import React, { useEffect, useState } from "react";
import { Flex, Grid, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { useErrorHandler } from "hooks/useErrorHandler";
import { ERROR_MESSAGE } from "variables/system";
import {
  fetchCommissionSetting,
  updateCommissionSetting,
} from "services/system-setting/commissionSettingService";
import { fetchMemberLevel } from "services/member-management/memberLevelService";
import CommissionForm from "./components/CommissionForm";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import { buildCommissionSettingPayload } from "../../../utils/PayloadUtils";

const CommissionSetting = ({ pageName }) => {
  const [commissionTurnOverData, setCommissionTurnOverData] = useState([]);
  const [commissionAffiliateData, setCommissionAffiliateData] = useState([]);
  const [commissionWinloseData, setCommissionWinloseData] = useState([]);
  const [memberLevelData, setMemberLevelData] = useState([]);
  const { onOpen } = useDisclosure();

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const fetchCommissionData = () => {
    fetchCommissionSetting()
      .then((response) => {
        setCommissionTurnOverData(response.data.data.commissionTurnOver);
        setCommissionAffiliateData(response.data.data.commissionAffiliate);
        setCommissionWinloseData(response.data.data.commissionWinlose);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const fetchMemberLevelData = () => {
    fetchMemberLevel()
      .then((response) => {
        setMemberLevelData(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchCommissionData();
    fetchMemberLevelData();
  }, []);

  const handleCommissionSubmit = (type, values) => {
    const preparedData = buildCommissionSettingPayload(
      type,
      values,
      commissionAffiliateData,
      commissionWinloseData,
      commissionTurnOverData,
    );
    updateCommissionSetting(preparedData)
      .then((response) => {
        handleSuccess(response.data.message);
        fetchCommissionData();
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const handleCommissionTurnOver = (values) => {
    handleCommissionSubmit("commissionTurnOver", values);
  };

  const handleCommissionAffiliate = (values) => {
    handleCommissionSubmit("commissionAffiliate", values);
  };

  const handleCommissionWinlose = (values) => {
    handleCommissionSubmit("commissionWinlose", values);
  };

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <Text fontWeight="bold" fontSize="lg">
          {pageName}
        </Text>
        <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
          <CommissionForm
            title="ตั้งค่าคอมมิชชั่น (แนะนำเพื่อน)"
            initialValues={{ commissions: commissionAffiliateData }}
            handleSubmit={handleCommissionAffiliate}
            memberLevelData={memberLevelData}
            openModal={onOpen}
            fetchCommissionData={fetchCommissionData}
          />
          <CommissionForm
            title="ตั้งค่าคอมมิชชั่น (แพ้/ชนะ)"
            initialValues={{ commissions: commissionWinloseData }}
            handleSubmit={handleCommissionWinlose}
            memberLevelData={memberLevelData}
            openModal={onOpen}
            fetchCommissionData={fetchCommissionData}
          />
          <CommissionForm
            title="ตั้งค่าคอมมิชชั่น (เทิร์นโอเวอร์)"
            initialValues={{ commissions: commissionTurnOverData }}
            handleSubmit={handleCommissionTurnOver}
            memberLevelData={memberLevelData}
            openModal={onOpen}
            fetchCommissionData={fetchCommissionData}
          />
        </Grid>
      </Stack>
    </Flex>
  );
};

export default CommissionSetting;
