import {
  Box,
  Button,
  Flex,
  Spacer,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import "assets/css/styles.css";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import { QueryClient, QueryClientProvider } from "react-query";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import moment from "moment/moment";
import SearchTableRemote from "components/Tables/SearchTableRemote";

import { fetchWithdrawHistories } from "services/transaction-report/withdrawTransactionReportService";
import { getReferenceDates } from "../../../utils/DateformatUtils";
import { withdrawTransactionColumnsData } from "variables/columns/transaction-report/withdrawTransactionReportColumnsData";
import RejectWithdrawTransactionModal from "./components/RejectWithdrawTransactionModal";
import ManualWithdrawTransactionModal from "./components/ManualWithdrawTransactionModal";
import UpdateWithdrawTransactionStatusModal from "./components/UpdateWithdrawTransactionStatusModal";
import SearchableDropdown from "../../../components/SearchableDropdown/SearchableDropdown";
import { mapJsonToTableData } from "../../../utils/CommonUtils";
import { useCSVDownloader } from "react-papaparse";
import { fetchMarketingDepositHistories } from "../../../services/transaction-report/depositTransactionReportService";

const WithdrawTransactionReport = ({ pageName }) => {
  const queryClient = new QueryClient();
  const { dateNow, dateDayCheck } = getReferenceDates();
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const [search, setSearch] = useState({});
  const [selectedDates, setSelectedDates] = useState([dateDayCheck, dateNow]);
  const [downloadableData, setDownloadableData] = useState([]);
  const { CSVDownloader, Type } = useCSVDownloader();
  const role = JSON.parse(localStorage.getItem("APP_PROFILE")).role;

  const searchOptions = [
    {
      label: "all",
      value: "",
    },
    {
      label: "success",
      value: "SUCCESS",
    },
    {
      label: "inprogress",
      value: "PENDING",
    },
    {
      label: "error",
      value: "ERROR",
    },
    {
      label: "reject",
      value: "REJECT",
    },
    {
      label: "rejectAndRefund",
      value: "REJECT_N_REFUND",
    },
    {
      label: "blockAuto",
      value: "BLOCK_AUTO",
    },
  ];

  const handleOptionChange = (selectedOption) => {
    setSearch({
      ...search,
      status: selectedOption ? selectedOption.value : null,
    });
  };

  const [modalState, setModalState] = useState({
    rejectWithdrawTransactionModalOpen: false,
    manualWithdrawTransactionModalOpen: false,
    updateWithdrawTransactionStatusModalOpen: false,
  });

  const handleModalOpen = (modal, transaction = null) => {
    setSelectedTransaction(transaction);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedTransaction(null);
  };

  const handleDateChange = (v) => {
    setSelectedDates(v);
    if (v.length === 2) {
      const startDate = moment(v[0]).startOf("day");
      const endDate = moment(v[1]).endOf("day");

      setSearch({
        ...search,
        createdDateFrom: startDate.format("DD/MM/yyyy HH:mm"),
        createdDateTo: endDate.format("DD/MM/yyyy HH:mm"),
      });
    } else {
      setSearch({
        ...search,
        createdDateFrom: moment(v[0]).startOf("day").format("DD/MM/yyyy HH:mm"),
        createdDateTo: moment(v[0]).endOf("day").format("DD/MM/yyyy HH:mm"),
      });
    }
  };

  const fetchData = async (pageIndex, pageSize, search) => {
    const response =
      role !== "MARKETING"
        ? await fetchWithdrawHistories(pageIndex, pageSize, search)
        : await fetchMarketingDepositHistories(pageIndex, pageSize, search);

    const records =
      role !== "MARKETING"
        ? await fetchWithdrawHistories(pageIndex, 1000, search)
        : await fetchMarketingDepositHistories(pageIndex, 1000, search);
    const formattedRecords = mapJsonToTableData(
      records.results,
      withdrawTransactionColumnsData(handleModalOpen),
    );

    setDownloadableData(formattedRecords);
    return response;
  };

  useEffect(() => {
    setSearch({
      createdDateFrom: moment(dateDayCheck).format("DD/MM/yyyy HH:mm"),
      createdDateTo: moment(dateNow).format("DD/MM/yyyy HH:mm"),
    });
    setIsLoading(false);
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Box>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Box>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column" justifyContent="center">
                <Text fontSize="18px" fontWeight="bold" mb="6px">
                  เช็คข้อมูลถอน
                </Text>
              </Flex>
              <Spacer />
              <Box width="235px">
                <SearchableDropdown
                  options={searchOptions}
                  isSearchable
                  placeholder="Search..."
                  onChange={handleOptionChange}
                />
              </Box>
              <Button
                borderColor={"green.500"}
                borderWidth="1px"
                ml={2}
                color="green.500"
                backgroundColor="transparent"
              >
                <CSVDownloader
                  filename={"ข้อมูลการถอน"}
                  data={downloadableData}
                  type={Type.Button}
                >
                  <Text fontWeight="bold">Export as CSV</Text>
                </CSVDownloader>
              </Button>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <QueryClientProvider client={queryClient}>
                  <SearchTableRemote
                    columnsData={withdrawTransactionColumnsData(
                      handleModalOpen,
                    )}
                    fetchData={fetchData}
                    search={search}
                    setSearch={setSearch}
                  />
                </QueryClientProvider>
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
        <RejectWithdrawTransactionModal
          isOpen={modalState.rejectWithdrawTransactionModalOpen}
          onClose={() => handleModalClose("rejectWithdrawTransactionModal")}
          setSearch={setSearch}
          transaction={selectedTransaction}
        />
        <ManualWithdrawTransactionModal
          isOpen={modalState.manualWithdrawTransactionModalOpen}
          onClose={() => handleModalClose("manualWithdrawTransactionModal")}
          setSearch={setSearch}
          transaction={selectedTransaction}
        />
        <UpdateWithdrawTransactionStatusModal
          isOpen={modalState.updateWithdrawTransactionStatusModalOpen}
          onClose={() =>
            handleModalClose("updateWithdrawTransactionStatusModal")
          }
          setSearch={setSearch}
          transaction={selectedTransaction}
        />
      </Stack>
    </Flex>
  );
};

export default WithdrawTransactionReport;
