import { Button, Flex, Spacer, Spinner, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import AnimatedFlex from "components/AnimatedFlex/MotionFlex";

import { QueryClient, QueryClientProvider } from "react-query";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { fetchMembersToday } from "services/member-management/memberService";
import { newMemberTodayColumnsData } from "variables/columns/member-management/newMemberTodayColumnsData";
import { getReferenceDates } from "../../../utils/DateformatUtils";
import moment from "moment";
import { mapJsonToTableData } from "../../../utils/CommonUtils";
import { useCSVDownloader } from "react-papaparse";

const NewMemberTodayReport = ({ pageName }) => {
  const queryClient = new QueryClient();

  const { dateNow, dateDayCheck } = getReferenceDates();

  const [search, setSearch] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [downloadableData, setDownloadableData] = useState([]);
  const { CSVDownloader, Type } = useCSVDownloader();

  useEffect(() => {
    setSearch({
      createdDateFrom: moment(dateDayCheck).format("DD/MM/yyyy HH:mm"),
      createdDateTo: moment(dateNow).format("DD/MM/yyyy HH:mm"),
    });
    setIsLoading(false);
  }, []);

  const fetchData = async (pageIndex, pageSize, search) => {
    const response = await fetchMembersToday(pageIndex, pageSize, search);
    const records = await fetchMembersToday(pageIndex, 1000, search);
    const formattedRecords = mapJsonToTableData(
      records.results,
      newMemberTodayColumnsData(),
    );

    setDownloadableData(formattedRecords);
    return response;
  };

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontSize="lg" fontWeight="bold">
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.8}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column" justifyContent="center">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  สมาชิกใหม่ทั้งหมด
                </Text>
              </Flex>
              <Spacer />
              <Button
                borderColor={"green.500"}
                borderWidth="1px"
                ml={2}
                color="green.500"
                backgroundColor="transparent"
              >
                <CSVDownloader
                  filename={"สมาชิกใหม่วันนี้"}
                  data={downloadableData}
                  type={Type.Button}
                >
                  <Text fontWeight="bold">Export as CSV</Text>
                </CSVDownloader>
              </Button>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <QueryClientProvider client={queryClient}>
                  <SearchTableRemote
                    columnsData={newMemberTodayColumnsData()}
                    fetchData={fetchData}
                    search={search}
                    setSearch={setSearch}
                  />
                </QueryClientProvider>
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default NewMemberTodayReport;
