import { Box, Flex, Spacer, Spinner, Stack, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import CardBody from "components/Card/CardBody";
import React, { useEffect, useState } from "react";
import { getReferenceDates } from "../../../utils/DateformatUtils";
import moment from "moment";
import { fetchAffiliateReport } from "services/member-report/affiliateReportService";
import { affiliateReportColumnsData } from "variables/columns/member-report/affiliateReportColumnsData";
import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import SearchTable1 from "components/Tables/SearchTable1";

const AffiliateReport = ({ pageName }) => {
  const { dateNow, dateDayCheck } = getReferenceDates();

  const [selectedDates, setSelectedDates] = useState([dateDayCheck, dateNow]);
  const [isLoading, setIsLoading] = useState(true);

  const [search, setSearch] = useState({});
  const [affiliateReportData, setAffiliateReportData] = useState([]);

  const handleError = useErrorHandler();

  const handleDateChange = (v) => {
    setSelectedDates(v);
    if (v.length === 2) {
      const startDate = moment(v[0]).startOf("day");
      const endDate = moment(v[1]).endOf("day");

      setSearch({
        ...search,
        listDateFrom: startDate.format("DD/MM/yyyy HH:mm:ss"),
        listDateTo: endDate.format("DD/MM/yyyy HH:mm:ss"),
      });
    } else {
      setSearch({
        ...search,
        listDateFrom: moment(v[0]).startOf("day").format("DD/MM/yyyy HH:mm:ss"),
        listDateTo: moment(v[0]).endOf("day").format("DD/MM/yyyy HH:mm:ss"),
      });
    }
  };

  const refetchData = () => {
    setIsLoading(true);
    fetchAffiliateReport(search)
      .then((response) => {
        setAffiliateReportData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    refetchData();
  }, [search, setSearch]);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Box>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Box>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" mb="6px">
                  รายงานแนะนำเพื่อน
                </Text>
              </Flex>
              <Spacer />
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <SearchTable1
                  tableData={affiliateReportData}
                  columnsData={affiliateReportColumnsData()}
                  hideSearch={false}
                />
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default AffiliateReport;
