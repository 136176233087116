import { QueryClient, QueryClientProvider } from "react-query";
import { getReferenceDates } from "../../../utils/DateformatUtils";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Button,
  Flex,
  Spacer,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import CardBody from "components/Card/CardBody";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { spinnerReportColumnsData } from "variables/columns/website-report/spinnerReportColumnsData";
import { fetchSpinnerHistory } from "services/system-setting/spinnerReportService";
import { mapJsonToTableData } from "../../../utils/CommonUtils";
import { useCSVDownloader } from "react-papaparse";

const SpinnerReport = ({ pageName }) => {
  const queryClient = new QueryClient();

  const { dateNow, dateDayCheck } = getReferenceDates();

  const [search, setSearch] = useState({});

  const [selectedDates, setSelectedDates] = useState([dateDayCheck, dateNow]);

  const [isLoading, setIsLoading] = useState(true);
  const [downloadableData, setDownloadableData] = useState([]);
  const { CSVDownloader, Type } = useCSVDownloader();

  useEffect(() => {
    setSearch({
      createdDateFrom: moment(dateDayCheck).format("DD/MM/yyyy HH:mm"),
      createdDateTo: moment(dateNow).format("DD/MM/yyyy HH:mm"),
    });
    setIsLoading(false);
  }, []);

  const handleDateChange = async (v) => {
    setSelectedDates(v);
    if (v.length === 2) {
      const startDate = moment(v[0]).startOf("day");
      const endDate = moment(v[1]).endOf("day");

      setSearch({
        ...search,
        createdDateFrom: startDate.format("DD/MM/yyyy HH:mm"),
        createdDateTo: endDate.format("DD/MM/yyyy HH:mm"),
      });

      try {
        const records = await fetchSpinnerHistory(0, 1000, search);
        console.log(records);
        const formattedRecords = mapJsonToTableData(
          records.results,
          spinnerReportColumnsData,
        );
        setDownloadableData(formattedRecords);
      } catch (e) {
        console.log(e);
      }
    } else {
      setSearch({
        ...search,
        createdDateFrom: moment(v[0]).startOf("day").format("DD/MM/yyyy HH:mm"),
        createdDateTo: moment(v[0]).endOf("day").format("DD/MM/yyyy HH:mm"),
      });
    }
  };

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontSize="lg" fontWeight="bold">
            {pageName}
          </Text>
          <Box>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Box>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.8}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column" justifyContent="center">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  ประวัติการการหมุนวงล้อ
                </Text>
              </Flex>
              <Spacer />
              <Button
                borderColor={"green.500"}
                borderWidth="1px"
                ml={2}
                color="green.500"
                backgroundColor="transparent"
              >
                <CSVDownloader
                  filename={"รายงานสรุป กำไร-ขาดทุน"}
                  data={downloadableData}
                  type={Type.Button}
                >
                  <Text fontWeight="bold">Export as CSV</Text>
                </CSVDownloader>
              </Button>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <QueryClientProvider client={queryClient}>
                  <SearchTableRemote
                    columnsData={spinnerReportColumnsData}
                    fetchData={fetchSpinnerHistory}
                    search={search}
                    setSearch={setSearch}
                  />
                </QueryClientProvider>
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default SpinnerReport;
