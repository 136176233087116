import { getReferenceDates } from "../../../utils/DateformatUtils";
import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { Box, Flex, Spacer, Spinner, Stack, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import CardBody from "components/Card/CardBody";

import { fetchCommissionReport } from "services/member-report/commissionReportService";
import { commissionReportColumnsData } from "variables/columns/member-report/commissionReportColumnsData";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import SearchTable1 from "components/Tables/SearchTable1";

const CommissionReport = ({ pageName }) => {
  const { dateNow, dateDayCheck } = getReferenceDates();

  const [selectedDates, setSelectedDates] = useState([dateDayCheck, dateNow]);
  const [isLoading, setIsLoading] = useState(true);

  const [search, setSearch] = useState({});
  const [commissionReportData, setCommissionReportData] = useState([]);

  const handleError = useErrorHandler();

  const searchOptions = [
    {
      label: "ทั้งหมด",
      value: "",
    },
    {
      label: "รับพ๊อยต์",
      value: "EARN_POINT",
    },
    {
      label: "ค่าคอมมิชชั่น",
      value: "COMM_CREDIT",
    },
    {
      label: "แลกพ๊อยต์",
      value: "TRANS_CREDIT",
    },
  ];

  const handleOptionChange = (selectedOption) => {
    setSearch({
      ...search,
      type: selectedOption ? selectedOption.value : null,
    });
  };

  const handleDateChange = (v) => {
    setSelectedDates(v);
    if (v.length === 2) {
      const startDate = moment(v[0]).startOf("day");
      const endDate = moment(v[1]).endOf("day");

      setSearch({
        ...search,
        createdDateFrom: startDate.format("DD/MM/yyyy HH:mm:ss"),
        createdDateTo: endDate.format("DD/MM/yyyy HH:mm:ss"),
      });
    } else {
      setSearch({
        ...search,
        createdDateFrom: moment(v[0])
          .startOf("day")
          .format("DD/MM/yyyy HH:mm:ss"),
        createdDateTo: moment(v[0]).endOf("day").format("DD/MM/yyyy HH:mm:ss"),
      });
    }
  };

  const refetchData = () => {
    setIsLoading(true);
    fetchCommissionReport(search)
      .then((response) => {
        setCommissionReportData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    refetchData();
  }, [search, setSearch]);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Box>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Box>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" mb="6px">
                  รายงานคอมมิชชั่น
                </Text>
              </Flex>
              <Spacer />
              <Box width="235px">
                <SearchableDropdown
                  options={searchOptions}
                  isSearchable={false}
                  onChange={handleOptionChange}
                />
              </Box>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <Flex direction="column" align="flex-end" mb="24px" w="100%">
                  <Box width="100%">
                    <SearchTable1
                      columnsData={commissionReportColumnsData()}
                      tableData={commissionReportData}
                    />
                  </Box>
                </Flex>
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default CommissionReport;
