import {
  Button,
  Flex,
  Icon,
  Input,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import { usePagination, useTable } from "react-table";

import { useQuery } from "react-query";

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: null,
};

const PAGE_CHANGED = "PAGE_CHANGED";
const PAGE_SIZE_CHANGED = "PAGE_SIZE_CHANGED";
const TOTAL_COUNT_CHANGED = "TOTAL_COUNT_CHANGED";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

function SearchTableRemote(props) {
  const {
    columnsData,
    query,
    fetchData,
    search,
    setSearch,
    isSearchable = true,
    isSum,
  } = props;

  const columns = useMemo(() => columnsData, []);
  // const data = useMemo(() => tableData, []);

  const [{ queryPageIndex, queryPageSize, totalCount }, dispatch] =
    React.useReducer(reducer, initialState);

  const { data, isSuccess } = useQuery(
    [query, queryPageIndex, queryPageSize, search],
    () => fetchData(queryPageIndex, queryPageSize, search),
    {
      keepPreviousData: false,
      staleTime: Infinity,
    },
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: isSuccess ? data.results : [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
      },
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: isSuccess ? Math.ceil(totalCount / queryPageSize) : null,
    },
    // useGlobalFilter,
    // useSortBy,
    usePagination,
  );

  React.useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  React.useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize, gotoPage]);

  React.useEffect(() => {
    if (data?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.count,
      });
    }
  }, [data?.count]);

  const createPages = (count) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  // const stackAlignment = useBreakpointValue({base: "center", md: "flex-end"});
  const stackAlignment = useBreakpointValue({ base: "center" });
  const stackMarginStart = useBreakpointValue({ base: "0", md: "auto" });

  const [pageChunk, setPageChunk] = useState(0);
  const pagesPerChunk = 5;

  return (
    <>
      <Flex direction="column" w="100%">
        <Flex justify="flex-end" align="center" w="100%" px="22px" my="22px">
          {isSearchable && (
            <Input
              type="text"
              placeholder="Search..."
              minW="75px"
              maxW="175px"
              fontSize="sm"
              _focus={{ borderColor: "green.500" }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  // setGlobalFilter(e.target.value)
                  setSearch({
                    ...search,
                    searchValue: e.target.value,
                  });
                  fetchData(queryPageIndex, queryPageSize, search);
                }
              }}
            />
          )}
        </Flex>
        <Box overflowX="auto" w="100%">
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            w="100%"
            minW="800px"
          >
            <Thead backgroundColor="rgb(29,29,29)">
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      // {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="0px"
                      key={index}
                      p={3}
                      minW="175px"
                      textAlign="center"
                      onClick={
                        column.disableSortBy
                          ? null
                          : () => {
                              if (search.sort === "A") {
                                setSearch({
                                  ...search,
                                  sortField: column.id,
                                  sort: "D",
                                });
                              } else {
                                setSearch({
                                  ...search,
                                  sortField: column.id,
                                  sort: "A",
                                });
                              }
                            }
                      }
                    >
                      <Flex
                        justify="center"
                        align="center"
                        fontSize="sm"
                        color="white"
                      >
                        {column.render("Header")}
                        {!column.disableSortBy && (
                          <Icon
                            w={{ sm: "10px", md: "14px" }}
                            h={{ sm: "10px", md: "14px" }}
                            color={column.isSorted ? "gray.500" : "gray.400"}
                            float="right"
                            as={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? TiArrowSortedDown
                                  : TiArrowSortedUp
                                : TiArrowUnsorted
                            }
                          />
                        )}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <Tr>
                  <Td
                    colSpan={headerGroups[0].headers.length}
                    textAlign="center"
                    fontSize="sm"
                    color="red.500"
                    p={4}
                  >
                    No matching records found
                  </Td>
                </Tr>
              ) : (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr
                      {...row.getRowProps()}
                      key={index}
                      sx={{
                        transition: "background-color 0.2s ease",
                        _hover: { backgroundColor: "gray.800" },
                      }}
                    >
                      {row.cells.map((cell, index) => (
                        <Td
                          {...cell.getCellProps()}
                          fontSize="sm"
                          key={index}
                          color="white"
                          p={2}
                          minW="175px"
                          textAlign="center"
                          className="td-border-bottom"
                        >
                          {cell.render("Cell")}
                        </Td>
                      ))}
                    </Tr>
                  );
                })
              )}
              {isSum && (
                <Tr backgroundColor={"#000000"}>
                  {columns.map((column, index) => (
                    <Td
                      key={index}
                      fontSize="sm"
                      color="white"
                      p={2}
                      minW="175px"
                      textAlign="center"
                      fontWeight="bold"
                    >
                      {index === columns.length - 2 ? (
                        <Text color="green.500">รวม</Text>
                      ) : index === columns.length - 1 ? (
                        data?.summary ?? ""
                      ) : (
                        ""
                      )}
                    </Td>
                  ))}
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
        <Flex
          direction={{ sm: "column", md: "row" }}
          justify="space-between"
          align="center"
          px="22px"
          w="100%"
          // px={{ md: "22px" }}
        >
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={{ sm: "4px", md: "12px" }}
            align="center"
            // me="12px"
            my="24px"
            minW={{ sm: "100px", md: "200px" }}
          >
            <Select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
              color="gray.400"
              size="sm"
              borderRadius="md"
              maxW="75px"
              cursor="pointer"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
            </Select>
            <Text fontSize="xs" color="gray.400" fontWeight="normal">
              entries per page
            </Text>
          </Stack>
          <Stack
            direction="row"
            alignSelf={stackAlignment}
            spacing="4px"
            ms={stackMarginStart}
          >
            <Button
              variant="no-hover"
              onClick={() => {
                if (pageChunk > 0) {
                  setPageChunk(pageChunk - 1);
                }
              }}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="6px"
              bg="gray.600"
              _hover={{ bg: "gray.500" }}
            >
              <Icon as={GrFormPrevious} w="16px" h="16px" color="gray.400" />
            </Button>
            {createPages(pageCount)
              .slice(pageChunk * pagesPerChunk, (pageChunk + 1) * pagesPerChunk)
              .map((pageNumber, index) => (
                <Button
                  variant="no-hover"
                  transition="all .5s ease"
                  onClick={() => gotoPage(pageNumber - 1)}
                  w="40px"
                  h="40px"
                  borderRadius="6px"
                  bg={pageNumber === pageIndex + 1 ? "green.500" : "gray.600"}
                  _hover={{ bg: "gray.500" }}
                  key={index}
                >
                  <Text fontSize="xs" color="#000000">
                    {pageNumber}
                  </Text>
                </Button>
              ))}
            <Button
              variant="no-hover"
              onClick={() => {
                if ((pageChunk + 1) * pagesPerChunk < pageCount) {
                  setPageChunk(pageChunk + 1);
                }
              }}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="6px"
              bg="gray.600"
              _hover={{ bg: "gray.500" }}
            >
              <Icon as={GrFormNext} w="16px" h="16px" color="gray.400" />
            </Button>
          </Stack>
        </Flex>
      </Flex>
    </>
  );
}

export default SearchTableRemote;
