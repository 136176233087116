import React, { useEffect, useState } from "react";
import { Box, Flex, Grid, IconButton, Input, Text } from "@chakra-ui/react";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import {
  fetchGamesByProvider,
  fetchGameSetting,
  fetchRecommendGames,
  saveRecommendGame,
} from "services/system-setting/gameSettingService";
import { useErrorHandler } from "hooks/useErrorHandler";
import { ERROR_MESSAGE } from "variables/system";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import { HiOutlineTrash } from "react-icons/hi";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";

const GameRecommendSetting = ({ pageName }) => {
  const [searchOptions, setSearchOptions] = useState([]);
  const [leftItems, setLeftItems] = useState([]);
  const [rightItems, setRightItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  const fetchAllProviders = () => {
    fetchGameSetting()
      .then((response) => {
        const options = response.data.data.gameSettingList
          .filter((setting) => setting.type === "SLOT")
          .map((setting) => ({
            value: setting.provider,
            label: `${setting.provider} (${setting.type})`,
          }));
        setSearchOptions(options);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const fetchCurrentRecommendGames = () => {
    fetchRecommendGames()
      .then((response) => {
        const games = response.data.data.map((game) => ({
          gameName: game.gameName,
          gameCode: game.gameCode,
          provider: game.provider,
          gameImage: game.gameImage,
        }));
        setRightItems(games);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const handleProviderSelect = (selectedOption) => {
    fetchGamesByProvider(selectedOption.value)
      .then((response) => {
        const games = response.data.data.games.map((game) => ({
          gameName: game.gameName,
          gameCode: game.gameCode,
          provider: response.data.data.provider,
          gameImage: game.gameImage,
        }));
        setLeftItems(games);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchAllProviders();
    fetchCurrentRecommendGames();
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    let sourceItems, destinationItems, setSourceItems, setDestinationItems;

    if (result.source.droppableId === "left") {
      sourceItems = Array.from(leftItems);
      destinationItems = Array.from(rightItems);
      setSourceItems = setLeftItems;
      setDestinationItems = setRightItems;
    } else {
      return;
    }

    const [movedItem] = sourceItems.splice(sourceIndex, 1);

    if (
      result.source.droppableId === "left" &&
      rightItems.some((item) => item.gameCode === movedItem.gameCode)
    ) {
      setSourceItems(sourceItems);
      return;
    }

    destinationItems.splice(destinationIndex, 0, movedItem);

    setSourceItems(sourceItems);
    setDestinationItems(destinationItems);

    if (result.source.droppableId === "left") {
      const data = [...rightItems, movedItem];
      const payload = { data: data };
      saveRecommendGame(payload)
        .then((response) => {
          handleSuccess(response.data.message);
        })
        .catch((error) => {
          handleError(error, ERROR_MESSAGE);
        });
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredLeftItems = leftItems.filter((item) =>
    item.gameName.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleDelete = (gameCode) => {
    const updatedRightItems = rightItems.filter(
      (item) => item.gameCode !== gameCode,
    );
    setRightItems(updatedRightItems);

    const payload = { data: updatedRightItems };

    saveRecommendGame(payload)
      .then((response) => {
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Box p={{ base: "0px 0px", "2xl": "0px 375px" }}>
        <Text fontSize="lg" fontWeight="bold" mb="4">
          {pageName}
        </Text>
        <Card w="100%" mb="6">
          <CardHeader>
            <Text fontSize="lg" fontWeight="bold" mb="4">
              เลือกค่ายเกมส์
            </Text>
          </CardHeader>
          <CardBody>
            <Box width="100%">
              <SearchableDropdown
                options={searchOptions}
                isSearchable
                placeholder="Search..."
                onChange={handleProviderSelect}
                fontSize="sm"
              />
            </Box>
          </CardBody>
        </Card>

        <Card w="100%">
          <CardBody>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Grid templateColumns="repeat(2, 1fr)" w="100%" gap={6}>
                <Box>
                  <Flex alignItems="center">
                    <Text fontSize="lg" fontWeight="bold" mr={1}>
                      รายชื่อเกมส์
                    </Text>
                    <Box as="span" color="green.500" fontWeight="normal">
                      (ยังไม่แนะนำ)
                    </Box>
                  </Flex>
                  <Droppable droppableId="left">
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        pt="4"
                        bg="gray.700"
                        borderRadius="md"
                        minHeight="400px"
                        fontSize="sm"
                      >
                        <Input
                          placeholder="Search..."
                          mb="4"
                          value={searchTerm}
                          onChange={handleSearch}
                          fontSize="sm"
                        />
                        {filteredLeftItems.map((item, index) => (
                          <Draggable
                            key={item.gameCode}
                            draggableId={item.gameCode}
                            index={index}
                          >
                            {(provided) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                mb="2"
                                p="4"
                                borderRadius="md"
                                border="1px"
                                borderColor="#52565b"
                              >
                                {item.gameName}
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Box>

                <Box>
                  <Flex alignItems="center">
                    <Text fontSize="lg" fontWeight="bold" mr={1}>
                      รายชื่อเกมส์
                    </Text>
                    <Box as="span" color="green.500" fontWeight="normal">
                      (แนะนำ)
                    </Box>
                  </Flex>
                  <Droppable droppableId="right">
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        pt="4"
                        bg="gray.700"
                        borderRadius="md"
                        minHeight="400px"
                      >
                        {rightItems.map((item, index) => (
                          <Draggable
                            key={item.gameCode}
                            draggableId={item.gameCode}
                            index={index}
                          >
                            {(provided) => (
                              <Flex
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                mb="2"
                                p="2"
                                borderRadius="md"
                                border="1px"
                                // borderColor="green.500"
                                borderColor="#52565b"
                                alignItems="center"
                                justifyContent="space-between"
                                fontSize="sm"
                              >
                                {item.gameName}
                                <IconButton
                                  size="sm"
                                  icon={<HiOutlineTrash />}
                                  variant="outline"
                                  borderColor={"red.500"}
                                  color={"red.500"}
                                  onClick={() => handleDelete(item.gameCode)}
                                />
                              </Flex>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Box>
              </Grid>
            </DragDropContext>
          </CardBody>
        </Card>
      </Box>
    </Flex>
  );
};

export default GameRecommendSetting;
