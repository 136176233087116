import { Button, HStack, Icon } from "@chakra-ui/react";
import { HiOutlineCheck, HiOutlineXMark } from "react-icons/hi2";
import { HiOutlineHand } from "react-icons/hi";

const ActionButtons = ({ row, handleModalOpen }) => {
  const status = row.original.status;
  const showButtons = ![
    "SUCCESS",
    "REJECT",
    "REJECT_N_REFUND",
    "PENDING",
  ].includes(status);

  return (
    showButtons && (
      <HStack spacing={2}>
        <Button
          leftIcon={<Icon as={HiOutlineCheck} boxSize={4} />}
          variant="outline"
          colorScheme="green"
          size="xs"
          fontSize="xs"
          padding={2}
          // onClick={() =>
          //   console.log(`Button แก้ไข id = ${row.original.id} is pressed`)
          // }
        >
          อนุมัติ
        </Button>
        <Button
          leftIcon={<Icon as={HiOutlineHand} boxSize={4} />}
          variant="outline"
          colorScheme="blue"
          size="xs"
          fontSize="xs"
          padding={2}
          onClick={() =>
            handleModalOpen("manualWithdrawTransactionModal", row.original)
          }
        >
          ถอนมือ
        </Button>
        <Button
          leftIcon={<Icon as={HiOutlineXMark} boxSize={4} />}
          variant="outline"
          colorScheme="red"
          size="xs"
          fontSize="xs"
          padding={2}
          onClick={() =>
            handleModalOpen("rejectWithdrawTransactionModal", row.original)
          }
        >
          ยกเลิก
        </Button>
      </HStack>
    )
  );
};

export default ActionButtons;
