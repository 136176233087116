import {
  Box,
  Button,
  Flex,
  Spacer,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { profitLossColumnsData } from "variables/columns/website-report/profitLossColumnsData";
import "assets/css/styles.css";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import SimpleTable from "components/Tables/SimpleTable";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import moment from "moment/moment";

import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import {
  fetchMarketingProfitLossReport,
  fetchProfitLossReport,
} from "services/website-report/profitLossReportService";
import { getReferenceDates } from "../../../utils/DateformatUtils";
import { mapJsonToTableData } from "../../../utils/CommonUtils";
import { useCSVDownloader } from "react-papaparse";

const ProfitLossReport = ({ pageName }) => {
  const { dateNow, dateDayCheck } = getReferenceDates();

  const [selectedDates, setSelectedDates] = useState([dateDayCheck, dateNow]);

  const [profitLossData, setProfitLossData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [downloadableData, setDownloadableData] = useState([]);
  const { CSVDownloader, Type } = useCSVDownloader();
  const handleError = useErrorHandler();
  const role = JSON.parse(localStorage.getItem("APP_PROFILE")).role;

  const handleSearchData = () => {
    const from = moment(selectedDates[0]).format("DD/MM/yyyy HH:mm");
    const to = moment(selectedDates[1]).format("DD/MM/yyyy HH:mm");

    const req = {
      createdDateFrom: from,
      createdDateTo: to,
      page: 0,
      size: 10,
      sort: "A",
      sortField: "id",
      totalSize: 0,
    };

    setIsLoading(true);

    const response =
      role !== "MARKETING"
        ? fetchProfitLossReport(req)
        : fetchMarketingProfitLossReport(req);

    response
      .then((response) => {
        setProfitLossData([response.data.data]);
        const formattedRecords = mapJsonToTableData(
          profitLossData,
          profitLossColumnsData,
        );
        setDownloadableData(formattedRecords);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    handleSearchData();
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Box>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={(v) => {
                setSelectedDates(v);
                if (v.length == 2) {
                  handleSearchData();
                }
              }}
            />
          </Box>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  สรุป กำไร-ขาดทุน
                </Text>
              </Flex>
              <Spacer />
              <Button
                borderColor={"green.500"}
                borderWidth="1px"
                ml={2}
                color="green.500"
                backgroundColor="transparent"
              >
                <CSVDownloader
                  filename={"รายงานสรุป กำไร-ขาดทุน"}
                  data={downloadableData}
                  type={Type.Button}
                >
                  <Text fontWeight="bold">Export as CSV</Text>
                </CSVDownloader>
              </Button>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <SimpleTable
                  tableData={profitLossData}
                  columnsData={profitLossColumnsData}
                />
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default ProfitLossReport;
