import { DEVICE_DROPDOWN } from "../variables/system";

export const numberToStringFormat = (num) => {
  const checknum = Number(num);
  if (!isNaN(checknum)) {
    return checknum.toLocaleString("en-US", { style: "decimal" });
  } else {
    return "";
  }
};

export const numberToStringFormat2Digit = (num) => {
  const checknum = Number(num);
  if (!isNaN(checknum)) {
    let digit2 = Number(checknum.toFixed(2));
    return digit2;
  } else {
    return "";
  }
};

export const validateField = (value) => {
  let error;
  if (!value) {
    error = "This field is required";
  }
  return error;
};

export const validatePassword = (value, staff) => {
  let error;
  if (!staff && !value) {
    error = "Password is required";
  }
  return error;
};

export const getDeviceText = (value) => {
  const device = DEVICE_DROPDOWN.find((device) => device.val === value);
  return device ? device.text : value;
};

export const getMemberLevelColorScheme = (value) => {
  switch (value) {
    case "Platinum":
      return "blue";
    case "Silver":
      return "gray";
    case "Gold":
      return "yellow";
    default:
      return "default";
  }
};

export const mapJsonToTableData = (jsonData, columnsData) => {
  return jsonData.map((item) => {
    const mappedRow = {};

    columnsData.forEach((column) => {
      const accessor = column.accessor;
      const header = column.Header;

      if (accessor && header) {
        mappedRow[header] = item[accessor] !== undefined ? item[accessor] : "-";
      }
    });

    return mappedRow;
  });
};
