import { useHistory } from "react-router-dom";
import {
  Badge,
  Flex,
  IconButton,
  Image,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import { banks } from "../../bank";
import { formatDate } from "../../../utils/DateformatUtils";
import { HiOutlineSquare2Stack } from "react-icons/hi2";

export const memberNotDepositColumnsData = () => {
  return [
    {
      Header: "ระดับ",
      accessor: "memberLevelName",
      Cell: ({ cell }) => (
        <Flex align="center" justify="center">
          <Badge
            colorScheme={
              cell.value === "Platinum"
                ? "blue"
                : cell.value === "Silver"
                ? "gray"
                : cell.value === "Gold"
                ? "yellow"
                : "default"
            }
            w="65px"
            h="28px"
            borderRadius="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {cell.value ? cell.value : "-"}
          </Badge>
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: "ยูสเซอร์",
      accessor: "username",
      Cell: ({ row }) => {
        const history = useHistory();
        const { onCopy } = useClipboard(row.original.username);

        return (
          <Flex align="center">
            <IconButton
              icon={<HiOutlineSquare2Stack />}
              onClick={onCopy}
              aria-label="Copy link"
              variant="outline"
              size="sm"
              border="none"
              mr={2}
            />
            <Text
              color={row.original.memberTagsColor}
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
              onClick={() =>
                history.push(`/admin/member/${row.original.username}`)
              }
            >
              {row.original.username}
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: "เบอร์โทรศัพท์",
      accessor: "tel",
    },
    {
      Header: "ชื่อ-นามสกุล",
      accessor: "fullName",
      disableSortBy: true,
      isSorted: false,
    },
    {
      Header: "ธนาคาร",
      accessor: "bankName",
      Cell: ({ cell, row }) => (
        <Flex align="center" justify="center" w="100%">
          <Image
            src={`${banks[row.original.bankName]?.logo}`}
            alt={cell.value}
            backgroundColor={banks[row.original.bankName]?.color}
            boxSize="30px"
            borderRadius="50%"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
          />
        </Flex>
      ),
    },
    {
      Header: "เลขบัญชี",
      accessor: "accountNumber",
    },
    {
      Header: "วันที่",
      accessor: "createdDate",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "ทำโดย",
      accessor: "createdBy",
    },
    {
      Header: "การตลาด",
      accessor: "marketing",
      disableSortBy: true,
    },
    {
      Header: "IP",
      accessor: "ip",
      disableSortBy: true,
    },
  ];
};
