import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Text,
  Checkbox,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import { ERROR_MESSAGE } from "variables/system";
import {
  saveRole,
  fetchRoleById,
} from "services/system-setting/roleSettingService";
import { groupPermissionsByMenuGroup } from "../../../../utils/RoleUtils";

const ManageRoleView = ({ pageName }) => {
  const { roleId } = useParams();
  const history = useHistory();
  const [roleData, setRoleData] = useState({ roleName: "", permissions: {} });
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();
  const [permissionsData, setPermissionsData] = useState([]);
  const [updateArray, setUpdateArray] = useState([]);

  const fetchPermissionData = () => {
    fetchRoleById(1)
      .then((response) => {
        const groupedPermissions = groupPermissionsByMenuGroup(
          response.data.data.menuList,
        );
        setPermissionsData(groupedPermissions);
        if (roleId) {
          fetchRoleById(roleId)
            .then((roleResponse) => {
              const rolePermissions = groupPermissionsByMenuGroup(
                roleResponse.data.data.menuList,
              );
              setRoleData({
                ...roleResponse.data.data,
                permissions: rolePermissions,
              });
              setUpdateArray(roleResponse.data.data.menuList);
            })
            .catch((error) => {
              handleError(error, ERROR_MESSAGE);
            });
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchPermissionData();
  }, [roleId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoleData({ ...roleData, [name]: value });
  };

  const handlePermissionChange = (menuGroup, menu) => {
    setRoleData((prevData) => {
      const updatedPermissions = { ...prevData.permissions };

      if (!updatedPermissions[menuGroup]) {
        updatedPermissions[menuGroup] = [];
      }

      if (
        updatedPermissions[menuGroup].some(
          (perm) => perm.menuId === menu.menuId,
        )
      ) {
        updatedPermissions[menuGroup] = updatedPermissions[menuGroup].filter(
          (perm) => perm.menuId !== menu.menuId,
        );
        setUpdateArray((prevArray) =>
          prevArray.filter((item) => item.menuId !== menu.menuId),
        );
      } else {
        updatedPermissions[menuGroup].push(menu);
        setUpdateArray((prevArray) => [...prevArray, menu]);
      }

      return { ...prevData, permissions: updatedPermissions };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      roleId: roleId ? parseInt(roleId) : null,
      roleName: roleData.roleName,
      menuList: updateArray,
    };

    saveRole(payload)
      .then((response) => {
        handleSuccess(response.data.message);
        history.push("/admin/system-setting/roles");
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <Text fontWeight="bold" fontSize="lg">
          {pageName}
        </Text>
        <AnimatedFlex duration={0.4} delay={0.4}>
          <Card w="100%">
            <CardBody>
              <Grid
                templateColumns={{
                  base: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                }}
                gap="24px"
                w="100%"
              >
                <GridItem colSpan={{ base: 1, sm: 2, md: 1 }}>
                  <FormControl mb="24px">
                    <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                      ชื่อตำแหน่ง
                    </FormLabel>
                    <Input
                      name="roleName"
                      fontSize="sm"
                      value={roleData.roleName}
                      onChange={handleChange}
                      isReadOnly={!!roleId}
                      backgroundColor={roleId ? "gray.800" : "gray.700"}
                    />
                  </FormControl>
                </GridItem>
                <GridItem />
                <GridItem />
                {Object.keys(permissionsData).map((menuGroup, index) => (
                  <GridItem key={index} colSpan={{ base: 1, sm: 1, md: 1 }}>
                    <Text
                      fontWeight="normal"
                      mb={2}
                      fontSize="sm"
                      color="green.500"
                    >
                      {menuGroup}
                    </Text>
                    <VStack align="start">
                      {permissionsData[menuGroup].map((menu) => (
                        <Checkbox
                          colorScheme="green"
                          key={menu.menuId}
                          isChecked={
                            roleData.permissions[menuGroup]?.some(
                              (perm) => perm.menuId === menu.menuId,
                            ) || false
                          }
                          onChange={() =>
                            handlePermissionChange(menuGroup, menu)
                          }
                          sx={{
                            "& .chakra-checkbox__control": {
                              _checked: {
                                bg: "green.500",
                                borderColor: "green.500",
                                color: "black",
                              },
                            },
                          }}
                        >
                          <Text fontSize="sm">{menu.menuCode}</Text>
                        </Checkbox>
                      ))}
                    </VStack>
                  </GridItem>
                ))}
              </Grid>
            </CardBody>
          </Card>
        </AnimatedFlex>
        <AnimatedFlex justify="flex-end" duration={0.4} delay={0.6}>
          <Button
            borderColor="green.500"
            borderWidth="1px"
            backgroundColor="transparent"
            color="green.500"
            width={{ base: "100%", md: "auto" }}
            minW={{ base: "none", md: "175px" }}
            borderRadius="6px"
            mr={2}
            onClick={() => history.push("/admin/system-setting/roles")}
          >
            ยกเลิก
          </Button>
          <Button
            backgroundColor="green.500"
            color={"#000000"}
            width={{ base: "100%", md: "auto" }}
            minW={{ base: "none", md: "175px" }}
            borderRadius="6px"
            onClick={handleSubmit}
          >
            บันทึกข้อมูล
          </Button>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default ManageRoleView;
