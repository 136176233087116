import { Badge, Flex } from "@chakra-ui/react";
import { numberWithCommas } from "../../../utils/ColumnsUtils";

export const staffCreditHistoryColumnsData = [
  {
    Header: "สถานะ",
    accessor: "mistakeType",
    Cell: ({ cell }) => (
      <Flex align="center" justify="center">
        <Badge
          colorScheme={
            cell.value === "CUT_CREDIT"
              ? "green"
              : cell.value === "NO_SLIP"
              ? "yellow"
              : "red"
          }
          w="85px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {cell.value === "CUT_CREDIT"
            ? "ลบเครดิต"
            : cell.value === "NO_SLIP"
            ? "สลิปไม่แสดง"
            : "เพิ่มเครดิต"}
        </Badge>
      </Flex>
    ),
  },
  {
    Header: "ยูสเซอร์",
    accessor: "username",
  },
  {
    Header: "เครดิตก่อนทำรายการ",
    accessor: "beforeAmount",
    Cell: ({ value }) => numberWithCommas(value),
  },
  {
    Header: "จำนวน",
    accessor: "amount",
    Cell: ({ value }) => numberWithCommas(value),
  },
  {
    Header: "เครดิตหลังทำรายการ",
    accessor: "afterAmount",
    Cell: ({ value }) => numberWithCommas(value),
  },

  {
    Header: "วันที่",
    accessor: "createdDate",
  },
  {
    Header: "หมายเหตุ",
    accessor: "reason",
  },
  {
    Header: "ทำโดย",
    accessor: "createdBy",
  },
];
