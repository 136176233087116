import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import { Field, FieldArray, Form, Formik } from "formik";
import ImageUploader from "components/ImageUploader/ImageUploader";
import {
  ERROR_MESSAGE,
  TYPEBONUS,
  TYPEGAME,
  TYPEPROMOTION,
  TYPETURN,
} from "variables/system";
import { fetchMemberLevel } from "services/member-management/memberLevelService";
import { useErrorHandler } from "hooks/useErrorHandler";
import { HiOutlinePlusSm, HiOutlineTrash } from "react-icons/hi";
import {
  addPromotionSetting,
  updatePromotionSetting,
} from "services/system-setting/promotionSettingService";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import CardFooter from "components/Card/CardFooter";
import { fetchGameSetting } from "services/system-setting/gameSettingService";
import { useHistory, useLocation } from "react-router-dom";

const ManagePromotionView = ({ pageName }) => {
  const location = useLocation();
  const promotion = location.state?.promotion || null;

  const initialValues = {
    urlImage: promotion?.urlImage || "",
    name: promotion?.name || "",
    typePromotion: promotion?.typePromotion || "",
    typeGame: promotion?.typeGame || "",
    typeBonus: promotion?.typeBonus || "",
    typeTurn: promotion?.typeTurn || "",
    maxBonus: promotion?.maxBonus || "",
    turnOver: promotion?.turnOver || "",
    maxWithdraw: promotion?.maxWithdraw || "",
    active: promotion?.active || false,
    description: promotion?.description || "",
    conditions: promotion?.conditions || [
      { minTopup: "", maxTopup: "", bonus: "" },
    ],
    maxReceiveBonus: promotion?.maxReceiveBonus || "",
    startTime: promotion?.startTime || "",
    endTime: promotion?.endTime || "",
    orderPromotion: promotion?.orderPromotion || "",
    memberLevelId: promotion?.memberLevelId || "",
    minTopup: promotion?.minTopup || "",
    maxTopup: promotion?.maxTopup || "",
    gameBlockList: promotion?.gameBlockList || [],
  };

  const gridTemplateColumns = useBreakpointValue({
    base: "1fr",
    lg: "1fr 2fr",
  });

  const [memberLevelData, setMemberLevelData] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState([]);

  useEffect(() => {
    fetchMemberLevel()
      .then((response) => {
        setMemberLevelData(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
    fetchProvidersByType();
  }, []);

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();
  const history = useHistory();
  const buttonSize = useBreakpointValue({ base: "100%", md: "30%" });

  const handleSubmit = (values, actions) => {
    const payload = promotion ? { ...promotion, ...values } : values;

    const response = promotion
      ? updatePromotionSetting(promotion?.id, payload)
      : addPromotionSetting(payload);

    response
      .then((response) => {
        if (response.status === 200) {
          handleSuccess(response.data.message);
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
        history.push("/admin/system-setting/promotions");
      });
  };

  const fetchProvidersByType = (type) => {
    let mappedType = "";

    if (type === "CASINO") {
      mappedType = "LIVE";
    } else if (type === "GAME") {
      mappedType = "SLOT";
    } else if (type === "TABLE_GAME") {
      mappedType = "CARD";
    } else if (type === "SPORT") {
      mappedType = "SPORT";
    }

    fetchGameSetting()
      .then((response) => {
        const providersData = response.data.data.gameSettingList;
        if (mappedType !== "") {
          const filtered = providersData.filter(
            (provider) => provider.type === mappedType,
          );
          setFilteredProviders(filtered);
        } else {
          setFilteredProviders(providersData);
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const handleToggleProvider = (
    providerId,
    providerName,
    setFieldValue,
    values,
  ) => {
    const isCurrentlyDisabled = values.gameBlockList.some(
      (item) => item.provider === providerName,
    );

    let updatedGameBlockList;

    if (isCurrentlyDisabled) {
      updatedGameBlockList = values.gameBlockList.filter(
        (item) => item.provider !== providerName,
      );
    } else {
      updatedGameBlockList = [
        ...values.gameBlockList,
        { provider: providerName },
      ];
    }

    setFieldValue("gameBlockList", updatedGameBlockList);
  };

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Box p={{ base: "0px 0px", "2xl": "0px 285px" }}>
        <Text fontWeight="bold" fontSize="lg" mb="4">
          {pageName}
        </Text>
        <AnimatedFlex duration={0.4} delay={0.4}>
          <Card p={8}>
            <Formik
              initialValues={initialValues}
              onSubmit={(values, actions) => {
                handleSubmit(values, actions);
              }}
              enableReinitialize
            >
              {({ values, handleChange, setFieldValue, isSubmitting }) => (
                <Form>
                  <Grid
                    templateColumns={gridTemplateColumns}
                    gap={8}
                    p={0}
                    m={0}
                  >
                    <GridItem p={0} m={0}>
                      <ImageUploader
                        initialImage={values.urlImage}
                        onUpload={(uri) => setFieldValue("urlImage", uri)}
                      />
                      <FormControl mt={4}>
                        <GridItem>
                          <FormControl>
                            <FormLabel fontSize="xs" fontWeight="bold">
                              ประเภทเกมส์
                            </FormLabel>
                            <Select
                              name="typeGame"
                              onChange={(e) => {
                                handleChange(e);
                                fetchProvidersByType(e.target.value);
                              }}
                              fontSize="sm"
                              value={values.typeGame}
                            >
                              {TYPEGAME.map((type, id) => (
                                <option key={id} value={type.typeCode}>
                                  {type.typeName}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                          {filteredProviders.length > 0 && (
                            <Box mt={4} maxHeight="525px" overflowY="auto">
                              {filteredProviders.map((provider) => {
                                const isDisabled = values.gameBlockList.some(
                                  (item) => item.provider === provider.provider,
                                );

                                return (
                                  <Flex
                                    key={provider.id}
                                    justify="space-between"
                                    align="center"
                                    mb={2}
                                  >
                                    <Text fontSize="sm">
                                      {provider.provider}
                                    </Text>
                                    <Button
                                      size="sm"
                                      onClick={() =>
                                        handleToggleProvider(
                                          provider.id,
                                          provider.provider,
                                          setFieldValue,
                                          values,
                                        )
                                      }
                                      color={
                                        isDisabled ? "red.500" : "green.500"
                                      }
                                      variant="outline"
                                      borderColor={
                                        isDisabled ? "red.500" : "green.500"
                                      }
                                    >
                                      {isDisabled ? "ปิด" : "เปิด"}
                                    </Button>
                                  </Flex>
                                );
                              })}
                            </Box>
                          )}
                        </GridItem>
                      </FormControl>
                    </GridItem>
                    <GridItem p={0} m={0}>
                      <Grid
                        templateColumns="repeat(3, 1fr)"
                        gap={4}
                        p={0}
                        m={0}
                      >
                        <GridItem colSpan={3}>
                          <FormControl>
                            <FormLabel fontSize="xs" fontWeight="bold">
                              ชื่อโปรโมชั่น
                            </FormLabel>
                            <Input
                              name="name"
                              onChange={handleChange}
                              value={values.name}
                              fontSize="sm"
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel fontSize="xs" fontWeight="bold">
                              ประเภทโปรโมชั่น
                            </FormLabel>
                            <Select
                              name="typePromotion"
                              onChange={handleChange}
                              value={values.typePromotion}
                              placeholder="กรุณาเลือกประเภท"
                              fontSize="sm"
                            >
                              {TYPEPROMOTION.map((type, id) => (
                                <option key={id} value={type.typeCode}>
                                  {type.typeName}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel fontSize="xs" fontWeight="bold">
                              ประเภทโบนัส
                            </FormLabel>
                            <Select
                              name="typeBonus"
                              onChange={handleChange}
                              value={values.typeBonus}
                              placeholder="กรุณาเลือกประเภท"
                              fontSize="sm"
                            >
                              {TYPEBONUS.map((type, id) => (
                                <option key={id} value={type.typeCode}>
                                  {type.typeName}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel fontSize="xs" fontWeight="bold">
                              ประเภทเทิร์น
                            </FormLabel>
                            <Select
                              name="typeTurn"
                              onChange={handleChange}
                              fontSize="sm"
                              value={values.typeTurn}
                              placeholder="กรุณาเลือกประเภท"
                            >
                              {TYPETURN.map((type, id) => (
                                <option key={id} value={type.typeCode}>
                                  {type.typeName}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        {(values.typePromotion === "ALLDAY" ||
                          values.typePromotion === "GOLDTIME") && (
                          <GridItem colSpan={3}>
                            <FormControl>
                              <FormLabel fontSize="xs" fontWeight="bold">
                                จำนวนครั้งในการรับโปรโมชั่นต่อวัน
                              </FormLabel>
                              <NumberInput
                                value={values.maxReceiveBonus}
                                onChange={(valueString) =>
                                  setFieldValue("maxReceiveBonus", valueString)
                                }
                              >
                                <NumberInputField
                                  name="maxReceiveBonus"
                                  placeholder="1000"
                                  fontSize="sm"
                                />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            </FormControl>
                          </GridItem>
                        )}
                        {values.typePromotion === "GOLDTIME" && (
                          <>
                            <GridItem>
                              <FormControl>
                                <FormLabel fontSize="xs" fontWeight="bold">
                                  เวลาเริ่มต้น
                                </FormLabel>
                                <Input
                                  name="startTime"
                                  size="md"
                                  type="time"
                                  value={values.startTime}
                                  onChange={(e) =>
                                    setFieldValue("startTime", e.target.value)
                                  }
                                  fontSize="sm"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel fontSize="xs" fontWeight="bold">
                                  เวลาเริ่มสิ้นสุด
                                </FormLabel>
                                <Input
                                  name="endTime"
                                  size="md"
                                  type="time"
                                  value={values.endTime}
                                  onChange={(e) =>
                                    setFieldValue("endTime", e.target.value)
                                  }
                                  fontSize="sm"
                                />
                              </FormControl>
                            </GridItem>
                          </>
                        )}
                        <GridItem>
                          <FormControl>
                            <FormLabel fontSize="xs" fontWeight="bold">
                              โบนัสสูงสุด
                            </FormLabel>
                            <NumberInput
                              value={values.maxBonus}
                              onChange={(valueString) =>
                                setFieldValue("maxBonus", valueString)
                              }
                            >
                              <NumberInputField
                                name="maxBonus"
                                placeholder="1000"
                                fontSize="sm"
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel fontSize="xs" fontWeight="bold">
                              ฝากต่ำสุด
                            </FormLabel>
                            <NumberInput
                              value={values.minTopup}
                              onChange={(valueString) =>
                                setFieldValue("minTopup", valueString)
                              }
                            >
                              <NumberInputField
                                name="minTopup"
                                placeholder="1000"
                                fontSize="sm"
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel fontSize="xs" fontWeight="bold">
                              ฝากสูงสุด
                            </FormLabel>
                            <NumberInput
                              value={values.maxTopup}
                              onChange={(valueString) =>
                                setFieldValue("maxTopup", valueString)
                              }
                            >
                              <NumberInputField
                                name="maxTopup"
                                placeholder="1000"
                                fontSize="sm"
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel fontSize="xs" fontWeight="bold">
                              เทิร์์นโอเวอร์
                            </FormLabel>
                            <NumberInput
                              value={values.turnOver}
                              onChange={(valueString) =>
                                setFieldValue("turnOver", valueString)
                              }
                            >
                              <NumberInputField
                                name="turnOver"
                                placeholder="1"
                                fontSize="sm"
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel fontSize="xs" fontWeight="bold">
                              เรียงลำดับ
                            </FormLabel>
                            <NumberInput
                              value={values.orderPromotion}
                              onChange={(valueString) =>
                                setFieldValue("orderPromotion", valueString)
                              }
                            >
                              <NumberInputField
                                name="orderPromotion"
                                placeholder="1"
                                fontSize="sm"
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel fontSize="xs" fontWeight="bold">
                              จำนวนยอดถอนสูงสุดกี่เท่า
                            </FormLabel>
                            <NumberInput
                              value={values.maxWithdraw}
                              onChange={(valueString) =>
                                setFieldValue("maxWithdraw", valueString)
                              }
                            >
                              <NumberInputField
                                name="maxWithdraw"
                                placeholder="10000"
                                fontSize="sm"
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>
                        </GridItem>
                      </Grid>
                      <Box mt={4}>
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ระดับสมาชิก
                          </FormLabel>
                          <Select
                            fontSize="sm"
                            name="memberLevelId"
                            onChange={handleChange}
                            placeholder="ทั้งหมด"
                            value={values.memberLevelId}
                          >
                            {memberLevelData.map((level) => (
                              <option key={level.id} value={level.id}>
                                {level.levelName}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                      <Flex align="center" mt={4}>
                        <Stack>
                          <FormLabel mb={0} fontSize="xs" fontWeight="bold">
                            Active
                          </FormLabel>
                          <Switch
                            ml={4}
                            isChecked={values.active}
                            onChange={(e) =>
                              setFieldValue("active", e.target.checked)
                            }
                          />
                        </Stack>
                      </Flex>
                      <GridItem mt={4}>
                        <FormControl>
                          <FormLabel
                            fontWeight="semibold"
                            fontSize="xs"
                            mb="10px"
                          >
                            รายละเอียด
                          </FormLabel>
                          <Field
                            as={Textarea}
                            name="description"
                            minHeight="125px"
                            fontSize="sm"
                          />
                        </FormControl>
                      </GridItem>
                      <FieldArray name="conditions">
                        {({ push, remove }) => (
                          <>
                            <Flex
                              justify="space-between"
                              align="center"
                              mt={4}
                              mb={4}
                            >
                              <Text fontSize="md" fontWeight="bold">
                                เงื่อนไข
                              </Text>
                              <Button
                                padding={2}
                                variant="outline"
                                borderColor={"green.500"}
                                color={"green.500"}
                                width="auto"
                                borderRadius="6px"
                                onClick={() =>
                                  push({
                                    minTopup: "",
                                    maxTopup: "",
                                    bonus: "",
                                  })
                                }
                              >
                                <HiOutlinePlusSm />
                              </Button>
                            </Flex>
                            <Divider my={4} borderColor="#ffffff14" />
                            <Grid
                              templateColumns={{
                                base: "1fr",
                                sm: "repeat(3, 1fr) auto",
                                md: "repeat(3, 1fr) auto",
                              }}
                              gap="16px"
                            >
                              {values.conditions.map((condition, index) => (
                                <React.Fragment key={index}>
                                  <GridItem colSpan={{ base: 1, sm: 1, md: 1 }}>
                                    <FormControl>
                                      <FormLabel
                                        fontWeight="semibold"
                                        fontSize="xs"
                                        mb="10px"
                                      >
                                        ต่ำสุด
                                      </FormLabel>
                                      <NumberInput
                                        value={condition.minTopup}
                                        onChange={(valueString) =>
                                          setFieldValue(
                                            `conditions.${index}.minTopup`,
                                            valueString,
                                          )
                                        }
                                      >
                                        <NumberInputField
                                          name={`conditions.${index}.minTopup`}
                                          placeholder="eg. 10000"
                                          fontSize="sm"
                                        />
                                        <NumberInputStepper>
                                          <NumberIncrementStepper />
                                          <NumberDecrementStepper />
                                        </NumberInputStepper>
                                      </NumberInput>
                                    </FormControl>
                                  </GridItem>
                                  <GridItem colSpan={{ base: 1, sm: 1, md: 1 }}>
                                    <FormControl>
                                      <FormLabel
                                        fontWeight="semibold"
                                        fontSize="xs"
                                        mb="10px"
                                      >
                                        สูงสุด
                                      </FormLabel>
                                      <NumberInput
                                        value={condition.maxTopup}
                                        onChange={(valueString) =>
                                          setFieldValue(
                                            `conditions.${index}.maxTopup`,
                                            valueString,
                                          )
                                        }
                                      >
                                        <NumberInputField
                                          name={`conditions.${index}.maxTopup`}
                                          placeholder="eg. 1000000"
                                          fontSize="sm"
                                        />
                                        <NumberInputStepper>
                                          <NumberIncrementStepper />
                                          <NumberDecrementStepper />
                                        </NumberInputStepper>
                                      </NumberInput>
                                    </FormControl>
                                  </GridItem>
                                  <GridItem colSpan={{ base: 1, sm: 1, md: 1 }}>
                                    <FormControl>
                                      <FormLabel
                                        fontWeight="semibold"
                                        fontSize="xs"
                                        mb="10px"
                                      >
                                        โบนัส
                                      </FormLabel>
                                      <NumberInput
                                        value={condition.bonus}
                                        onChange={(valueString) =>
                                          setFieldValue(
                                            `conditions.${index}.bonus`,
                                            valueString,
                                          )
                                        }
                                      >
                                        <NumberInputField
                                          name={`conditions.${index}.bonus`}
                                          placeholder="eg. 1"
                                          fontSize="sm"
                                        />
                                        <NumberInputStepper>
                                          <NumberIncrementStepper />
                                          <NumberDecrementStepper />
                                        </NumberInputStepper>
                                      </NumberInput>
                                    </FormControl>
                                  </GridItem>
                                  <GridItem
                                    colSpan={1}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="flex-end"
                                  >
                                    {index > 0 ? (
                                      <IconButton
                                        variant="outline"
                                        borderColor={"red.500"}
                                        color={"red.500"}
                                        aria-label="delete"
                                        size="md"
                                        icon={<HiOutlineTrash />}
                                        onClick={() => remove(index)}
                                      />
                                    ) : (
                                      <IconButton variant="ghost" disabled />
                                    )}
                                  </GridItem>
                                </React.Fragment>
                              ))}
                            </Grid>
                          </>
                        )}
                      </FieldArray>
                    </GridItem>
                  </Grid>
                  <CardFooter pl={0} pr={0} mt={8} justifyContent="center">
                    <Button
                      backgroundColor="green.500"
                      color={"#000000"}
                      type="submit"
                      w={buttonSize}
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      ยืนยัน
                    </Button>
                  </CardFooter>
                </Form>
              )}
            </Formik>
          </Card>
        </AnimatedFlex>
      </Box>
    </Flex>
  );
};

export default ManagePromotionView;
