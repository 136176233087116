import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  Input,
  Grid,
  GridItem,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import { SimpleHSeparator } from "components/Separator/Separator";

const CommissionSettingModal = ({
  isOpen,
  onClose,
  modalData,
  handleSubmit,
}) => {
  const [localData, setLocalData] = useState(modalData);

  useEffect(() => {
    setLocalData(modalData);
  }, [modalData]);

  const handleInputChange = (event, provider) => {
    const { value } = event.target;
    const updatedData = localData.map((item) =>
      item.provider === provider ? { ...item, percent: value } : item,
    );
    setLocalData(updatedData);
  };

  const handleSave = () => {
    handleSubmit(localData);
    onClose();
  };

  const renderProviders = (type, label) => (
    <>
      <Text fontSize="md" fontWeight="bold" mt={2}>
        รายชื่อค่าย - {label}
      </Text>
      <SimpleHSeparator mt={2} mb={2} />
      <Grid templateColumns="repeat(6, 1fr)" gap={6}>
        {localData
          .filter((provider) => provider[type] && provider.provider !== "UFA")
          .map((provider) => (
            <GridItem key={provider.provider}>
              <Box mb={4}>
                <Text fontSize="xs" color="green.500">
                  {provider.provider}
                </Text>
                <InputGroup mt={2}>
                  <Input
                    name={provider.provider}
                    value={provider.percent}
                    onChange={(e) => handleInputChange(e, provider.provider)}
                  />
                  <InputRightElement width="3rem">
                    <Text>%</Text>
                  </InputRightElement>
                </InputGroup>
              </Box>
            </GridItem>
          ))}
      </Grid>
    </>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          {renderProviders("isSlot", "Slot")}
          {renderProviders("isLive", "Casino")}
          {renderProviders("isCard", "Card")}
          {renderProviders("isFishing", "Fishing")}
          {renderProviders("isSport", "Sport")}
        </ModalBody>
        <ModalFooter>
          <Button
            color="#000000"
            backgroundColor="green.500"
            onClick={handleSave}
          >
            บันทึก
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CommissionSettingModal;
