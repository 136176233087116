import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
} from "@chakra-ui/react";
import React from "react";

import { ERROR_MESSAGE, NO_SLIP } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import { Field, Form, Formik } from "formik";
import { validateField } from "../../../../utils/CommonUtils";
import { createMistakeCredit } from "services/member-management/memberService";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import ImageUploader from "../../../../components/ImageUploader/ImageUploader";

const AddCreditSlipModal = ({ isOpen, onClose, setSearch, member }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const handleAddCreditSlip = async (values, actions) => {
    createMistakeCredit(values)
      .then((response) => {
        onClose();
        if (response.status === 200) {
          handleSuccess(response.data.message);
          setSearch();
        }
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent p={8}>
        <ModalHeader>
          <Box textAlign="center">
            <Text fontSize="26px">เพิ่่มรายการฝาก</Text>
            <Text
              fontSize="15px"
              color="rgb(116,116,116)"
              fontWeight="normal"
              mt={4}
            >
              เพิ่มรายการฝาก (สลิปไม่แสดง)
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              type: NO_SLIP,
              username: member?.username,
              credit: "",
              reason: "",
              turnover: 0,
            }}
            onSubmit={(values, actions) => {
              handleAddCreditSlip(values, actions);
            }}
          >
            {(props) => (
              <Form>
                <Field name="credit" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.credit && form.touched.credit}
                      isRequired
                    >
                      <FormLabel fontSize="xs" fontWeight="bold">
                        จำนวนเงิน
                      </FormLabel>
                      <NumberInput
                        defaultValue={0}
                        precision={2}
                        step={0.2}
                        value={props.values.credit}
                        onChange={(valueString) =>
                          props.setFieldValue("credit", valueString)
                        }
                        fontSize="sm"
                      >
                        <NumberInputField ref={initialRef} {...field} />
                      </NumberInput>
                    </FormControl>
                  )}
                </Field>
                <ImageUploader
                  initialImage={""}
                  onUpload={(uri) => props.setFieldValue("image", uri)}
                />
                <Field name="reason" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      mt={4}
                      isInvalid={form.errors.reason && form.touched.reason}
                      isRequired
                    >
                      <FormLabel fontSize="xs" fontWeight="bold">
                        หมายเหตุ
                      </FormLabel>
                      <Input {...field} placeholder="หมายเหตุ" fontSize="sm" />
                    </FormControl>
                  )}
                </Field>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    backgroundColor="green.500"
                    color={"#000000"}
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                    disabled={props.isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddCreditSlipModal;
