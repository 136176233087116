import { Switch, Text } from "@chakra-ui/react";

export const gameSettingColumnsData = (handleToggle) => [
  {
    Header: "ผู้ให้บริการ",
    Cell: ({ row }) => {
      const isAmb = row.original.isAmb;
      const provider = row.original.provider;

      let displayText = "AMB";
      let displayColor = "green.500";

      if (isAmb === false && provider !== "PGSOFT0" && provider !== "UFA") {
        displayText = "BETFLIX";
        displayColor = "red.500";
      } else if (
        provider === "PGSOFT0" &&
        isAmb === false &&
        provider !== "UFA"
      ) {
        displayText = "ASGSOFT";
        displayColor = "yellow";
      } else if (provider === "UFA") {
        displayText = "UFABET";
        displayColor = "#ffecb3";
      }

      return (
        <Text as="span" color={displayColor}>
          {displayText}
        </Text>
      );
    },
  },
  {
    Header: "เกมส์",
    accessor: "gamename",
    disableSortBy: true,
    Cell: ({ row }) => <Text>{row.original.gamename}</Text>,
  },
  {
    Header: "สถานะ",
    accessor: "isEnable",
    Cell: ({ row, value }) => (
      <Switch
        isChecked={value}
        onChange={(e) =>
          handleToggle(
            row.original.provider,
            row.original.type,
            e.target.checked,
          )
        }
      />
    ),
    disableSortBy: true,
  },
];
