import {
  HiOutlineCash,
  HiOutlineChartPie,
  HiOutlineCog,
  HiOutlineCurrencyDollar,
  HiOutlineDocumentReport,
  HiOutlineGlobe,
  HiOutlineIdentification,
  HiOutlineUserGroup,
} from "react-icons/hi";
import Dashboard from "./views/dashboard";
import Thief from "./views/theif";
import Members from "./views/member-management/members";
import MemberLevel from "./views/member-management/member-level";
import MemberTag from "./views/member-management/member-tag";
import NewMemberTodayReport from "./views/member-report/new-member-today";
import MemberDepositReport from "./views/member-report/member-deposit";
import MemberNotDepositReport from "./views/member-report/member-not-deposit";
import MemberFirstDepositReport from "./views/member-report/member-first-deposit";
import MemberTransactionReport from "./views/member-report/member-transaction";
import PromotionReport from "./views/member-report/promotion";
import CommissionReport from "./views/member-report/commission";
import AffiliateReport from "./views/member-report/affiliate";
import DepositTransactionReport from "./views/transaction-report/deposit";
import WithdrawTransactionReport from "./views/transaction-report/withdraw";
import { HiComputerDesktop } from "react-icons/hi2";
import ProfitLossReport from "./views/website-report/profit-loss";
import MarketingReport from "./views/website-report/marketing";
import SpinnerReport from "./views/website-report/spinner";
import JackpotReport from "./views/website-report/jackpot";
import MemberLoginReport from "./views/website-report/member-login";
import StaffLoginReport from "./views/website-report/staff-login";
import StaffCreditReport from "./views/website-report/staff-credit";
import WebSetting from "./views/website-setting/web";
import ActivityImageSetting from "./views/website-setting/activity-image";
import SeoSetting from "./views/website-setting/seo";
import CanonicalSetting from "./views/website-setting/canonical";
import FooterSetting from "./views/website-setting/footer";
import LiveChatSetting from "./views/website-setting/live-chat";
import BannerSetting from "./views/website-setting/banner";
import PopupSetting from "./views/website-setting/popup";
import BanksSetting from "./views/finance-setting/banks";
import TrueWalletSetting from "./views/finance-setting/true-wallet";
import PaymentGatewaySetting from "./views/finance-setting/payment-gateway";
import ConditionSetting from "./views/system-setting/condition";
import PromotionSetting from "./views/system-setting/promotion";
import CommissionSetting from "./views/system-setting/comission";
import AffiliateSetting from "./views/system-setting/affiliate";
import MarketingSetting from "./views/system-setting/marketing";
import StaffSetting from "./views/system-setting/staff";
import RoleSetting from "./views/system-setting/role";
import GameSetting from "./views/system-setting/game";
import GameRecommendSetting from "./views/system-setting/game-recommend";
import SpinnerSetting from "./views/system-setting/spinner";
import JackpotSetting from "./views/system-setting/jackpot";
import InvalidTransactionReport from "./views/transaction-report/invalid";

const allRoutes = [
  {
    menuId: 1,
    name: "ภาพรวม",
    path: "/dashboard",
    icon: <HiOutlineChartPie />,
    collapse: false,
    items: [],
    component: (props) => <Dashboard {...props} pageName="ภาพรวม" />,
    layout: "/admin",
  },
  {
    menuId: 2,
    name: "รายชื่อมิจฉาชีพ",
    path: "/thief",
    icon: <HiOutlineIdentification />,
    collapse: false,
    items: [],
    component: (props) => <Thief {...props} pageName="รายชื่อมิจฉาชีพ" />,
    layout: "/admin",
  },
  {
    name: "จัดการสมาชิก",
    path: "/member-management",
    icon: <HiOutlineUserGroup />,
    collapse: true,
    items: [
      {
        menuId: 3,
        name: "สมาชิกทั้งหมด",
        path: "/member-management/members",
        component: (props) => <Members {...props} pageName="สมาชิกทั้งหมด" />,
        layout: "/admin",
      },
      {
        menuId: 4,
        name: "ระดับสมาชิก",
        path: "/member-management/level",
        component: (props) => <MemberLevel {...props} pageName="ระดับสมาชิก" />,
        layout: "/admin",
      },
      {
        menuId: 5,
        name: "แท็คสมาชิก",
        path: "/member-management/tag",
        component: (props) => <MemberTag {...props} pageName="แท็คสมาชิก" />,
        layout: "/admin",
      },
    ],
  },
  {
    name: "รายงานสมาชิก",
    path: "/member-report",
    icon: <HiOutlineDocumentReport />,
    collapse: true,
    items: [
      {
        menuId: 6,
        name: "สมาชิกใหม่วันนี้",
        path: "/member-report/today",
        component: (props) => (
          <NewMemberTodayReport {...props} pageName="สมาชิกใหม่วันนี้" />
        ),
        layout: "/admin",
      },
      {
        menuId: 7,
        name: "สมาชิกที่มีการฝาก",
        path: "/member-report/deposit",
        component: (props) => (
          <MemberDepositReport {...props} pageName="สมาชิกที่มีการฝาก" />
        ),
        layout: "/admin",
      },
      {
        menuId: 8,
        name: "สมาชิกสมัครไม่ฝาก",
        path: "/member-report/not-deposit",
        component: (props) => (
          <MemberNotDepositReport {...props} pageName="สมาชิกสมัครไม่ฝาก" />
        ),
        layout: "/admin",
      },
      {
        menuId: 9,
        name: "สมาชิกฝากครั้งแรก",
        path: "/member-report/first-deposit",
        component: (props) => (
          <MemberFirstDepositReport {...props} pageName="สมาชิกฝากครั้งแรก" />
        ),
        layout: "/admin",
      },
      {
        menuId: 10,
        name: "ฝากถอนสมาชิก",
        path: "/member-report/transaction",
        component: (props) => (
          <MemberTransactionReport {...props} pageName="ฝากถอนสมาชิก" />
        ),
        layout: "/admin",
      },
      {
        menuId: 11,
        name: "รายงานโปรโมชั่น",
        path: "/member-report/promotion",
        component: (props) => (
          <PromotionReport {...props} pageName="รายงานโปรโมชั่น" />
        ),
        layout: "/admin",
      },
      {
        menuId: 12,
        name: "รายงานคอมมิชชั่น",
        path: "/member-report/commission",
        component: (props) => (
          <CommissionReport {...props} pageName="รายงานคอมมิชชั่น" />
        ),
        layout: "/admin",
      },
      {
        menuId: 13,
        name: "รายงานแนะนำเพื่อน",
        path: "/member-report/affiliate",
        component: (props) => (
          <AffiliateReport {...props} pageName="รายงานแนะนำเพื่อน" />
        ),
        layout: "/admin",
      },
    ],
  },
  {
    name: "รายงานการเงิน",
    path: "/transaction-report",
    icon: <HiOutlineCash />,
    collapse: true,
    items: [
      {
        menuId: 14,
        name: "รายงานฝาก",
        path: "/transaction-report/deposit",
        component: (props) => (
          <DepositTransactionReport {...props} pageName="รายงานฝาก" />
        ),
        layout: "/admin",
      },
      {
        menuId: 15,
        name: "รายงานถอน",
        path: "/transaction-report/withdraw",
        component: (props) => (
          <WithdrawTransactionReport {...props} pageName="รายงานถอน" />
        ),
        layout: "/admin",
      },
      {
        menuId: 16,
        name: "รายการไม่พบบัญชี",
        path: "/transaction-report/invalid",
        component: (props) => (
          <InvalidTransactionReport {...props} pageName="รายการไม่พบบัญชี" />
        ),
        layout: "/admin",
      },
    ],
  },
  {
    name: "รายงานเว็บไซต์",
    path: "/website-report",
    icon: <HiComputerDesktop />,
    collapse: true,
    items: [
      {
        menuId: 17,
        name: "สรุปกำไร-ขาดทุน",
        path: "/website-report/profit-loss",
        component: (props) => (
          <ProfitLossReport {...props} pageName="สรุปกำไร-ขาดทุน" />
        ),
        layout: "/admin",
      },
      {
        menuId: 18,
        name: "รายงานการตลาด",
        path: "/website-report/marketing",
        component: (props) => (
          <MarketingReport {...props} pageName="รายงานการตลาด" />
        ),
        layout: "/admin",
      },
      {
        menuId: 19,
        name: "รายงานวงล้อ",
        path: "/website-report/spinner",
        component: (props) => (
          <SpinnerReport {...props} pageName="รายงานวงล้อ" />
        ),
        layout: "/admin",
      },
      {
        menuId: 20,
        name: "รายงานแจ๊คพ๊อต",
        path: "/website-report/jackpot",
        component: (props) => (
          <JackpotReport {...props} pageName="รายงานแจ๊คพ๊อต" />
        ),
        layout: "/admin",
      },
      {
        menuId: 21,
        name: "ประวัติการเข้าสู่ระบบ",
        path: "/website-report/member-login-history",
        component: (props) => (
          <MemberLoginReport {...props} pageName="ประวัติการเข้าสู่ระบบ" />
        ),
        layout: "/admin",
      },
      {
        menuId: 22,
        name: "ประวัติเข้าสู่ระบบพนักงาน",
        path: "/website-report/staff-login-history",
        component: (props) => (
          <StaffLoginReport {...props} pageName="ประวัติเข้าสู่ระบบพนักงาน" />
        ),
        layout: "/admin",
      },
      {
        menuId: 23,
        name: "ประวัติเครดิตพนักงาน",
        path: "/website-report/staff-credit-history",
        component: (props) => (
          <StaffCreditReport {...props} pageName="ประวัติเครดิตพนักงาน" />
        ),
        layout: "/admin",
      },
    ],
  },
  {
    name: "ตั้งค่าเว็บไซต์",
    path: "/website-setting",
    icon: <HiOutlineGlobe />,
    collapse: true,
    items: [
      {
        menuId: 24,
        name: "ตั้งค่าหน้าเว็บ",
        path: "/website-setting/web",
        component: (props) => (
          <WebSetting {...props} pageName="ตั้งค่าหน้าเว็บ" />
        ),
        layout: "/admin",
      },
      {
        menuId: 25,
        name: "ตั้งค่ารูปภาพกิจกรรม",
        path: "/website-setting/activity-image",
        component: (props) => (
          <ActivityImageSetting {...props} pageName="ตั้งค่ารูปภาพกิจกรรม" />
        ),
        layout: "/admin",
      },
      {
        menuId: 26,
        name: "ตั้งค่า SEO",
        path: "/website-setting/seo",
        component: (props) => <SeoSetting {...props} pageName="ตั้งค่า SEO" />,
        layout: "/admin",
      },
      {
        menuId: 27,
        name: "ตั้งค่า Canonical",
        path: "/website-setting/canonical",
        component: (props) => (
          <CanonicalSetting {...props} pageName="ตั้งค่า Canonical" />
        ),
        layout: "/admin",
      },
      {
        menuId: 28,
        name: "ตั้งค่า Footer",
        path: "/website-setting/footer",
        component: (props) => (
          <FooterSetting {...props} pageName="ตั้งค่า Footer" />
        ),
        layout: "/admin",
      },
      {
        menuId: 29,
        name: "ตั้งค่า LiveChat",
        path: "/website-setting/live-chat",
        component: (props) => (
          <LiveChatSetting {...props} pageName="ตั้งค่า LiveChat" />
        ),
        layout: "/admin",
      },
      {
        menuId: 30,
        name: "ตั้งค่าแบนเนอร์",
        path: "/website-setting/banner",
        component: (props) => (
          <BannerSetting {...props} pageName="ตั้งค่าแบนเนอร์" />
        ),
        layout: "/admin",
      },
      {
        menuId: 31,
        name: "ตั้งค่าป๊อปอัพ",
        path: "/website-setting/popup",
        component: (props) => (
          <PopupSetting {...props} pageName="ตั้งค่าป๊อปอัพ" />
        ),
        layout: "/admin",
      },
    ],
  },
  {
    name: "ตั้งค่าการเงิน",
    path: "/finance-setting",
    icon: <HiOutlineCurrencyDollar />,
    collapse: true,
    items: [
      {
        menuId: 43,
        name: "ตั้งค่าธนาคาร",
        path: "/finance-setting/bank",
        component: (props) => (
          <BanksSetting {...props} pageName="ตั้งค่าธนาคาร" />
        ),
        layout: "/admin",
      },
      {
        menuId: 44,
        name: "ตั้งค่าทรูวอลเล็ต",
        path: "/finance-setting/true-wallet",
        component: (props) => (
          <TrueWalletSetting {...props} pageName="ตั้งค่าทรูวอลเล็ต" />
        ),
        layout: "/admin",
      },
      {
        menuId: 45,
        name: "ตั้งค่า Payment Gateway",
        path: "/finance-setting/payment-gateway",
        component: (props) => (
          <PaymentGatewaySetting
            {...props}
            pageName="ตั้งค่า Payment Gateway"
          />
        ),
        layout: "/admin",
      },
    ],
  },
  {
    name: "ตั้งค่าระบบ",
    path: "/system-setting",
    icon: <HiOutlineCog />,
    collapse: true,
    items: [
      {
        menuId: 32,
        name: "เงื่อนไขเว็บไซต์",
        path: "/system-setting/condition",
        component: (props) => (
          <ConditionSetting {...props} pageName="เงื่อนไขเว็บไซต์" />
        ),
        layout: "/admin",
      },
      {
        menuId: 33,
        name: "ตั้งค่าโปรโมชั่น",
        path: "/system-setting/promotions",
        component: (props) => (
          <PromotionSetting {...props} pageName="ตั้งค่าโปรโมชั่น" />
        ),
        layout: "/admin",
      },
      {
        menuId: 34,
        name: "ตั้งค่าคอมมิชชั่น",
        path: "/system-setting/commission",
        component: (props) => (
          <CommissionSetting {...props} pageName="ตั้งค่าคอมมิชชั่น" />
        ),
        layout: "/admin",
      },
      {
        menuId: 35,
        name: "ตั้งค่าแนะนำเพื่อน",
        path: "/system-setting/affiliate",
        component: (props) => (
          <AffiliateSetting {...props} pageName="ตั้งค่าแนะนำเพื่อน" />
        ),
        layout: "/admin",
      },
      {
        menuId: 36,
        name: "ตั้งค่าการตลาด",
        path: "/system-setting/marketing",
        component: (props) => (
          <MarketingSetting {...props} pageName="ตั้งค่าการตลาด" />
        ),
        layout: "/admin",
      },
      {
        menuId: 37,
        name: "ตั้งค่าพนักงาน",
        path: "/system-setting/staff",
        component: (props) => (
          <StaffSetting {...props} pageName="ตั้งค่าพนักงาน" />
        ),
        layout: "/admin",
      },
      {
        menuId: 38,
        name: "ตั้งค่าตำแหน่งและสิทธิ์",
        path: "/system-setting/roles",
        component: (props) => (
          <RoleSetting {...props} pageName="ตั้งค่าตำแหน่งและสิทธิ์" />
        ),
        layout: "/admin",
      },
      {
        menuId: 39,
        name: "ตั้งค่าเกมส์",
        path: "/system-setting/game",
        component: (props) => (
          <GameSetting {...props} pageName="ตั้งค่าเกมส์" />
        ),
        layout: "/admin",
      },
      {
        menuId: 40,
        name: "ตั้งค่าเกมส์แนะนำ",
        path: "/system-setting/recommend-game",
        component: (props) => (
          <GameRecommendSetting {...props} pageName="ตั้งค่าเกมส์แนะนำ" />
        ),
        layout: "/admin",
      },
      {
        menuId: 41,
        name: "ตั้งค่าวงล้อ",
        path: "/system-setting/spinner",
        component: (props) => (
          <SpinnerSetting {...props} pageName="ตั้งค่าวงล้อ" />
        ),
        layout: "/admin",
      },
      {
        menuId: 42,
        name: "ตั้งค่าแจ๊คพ๊อต",
        path: "/system-setting/jackpot",
        component: (props) => (
          <JackpotSetting {...props} pageName="ตั้งค่าแจ๊คพ๊อต" />
        ),
        layout: "/admin",
      },
    ],
  },
];

export default allRoutes;
