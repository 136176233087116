import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import { Element } from "react-scroll";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "hooks/useErrorHandler";

import { ERROR_MESSAGE, TYPEREFUND } from "variables/system";
import { Form, Formik } from "formik";
import {
  fetchWebSetting,
  updateWebSetting,
} from "services/website-setting/webSettingService";
import { useSuccessHandler } from "hooks/useSuccessHandler";

const ConditionSetting = ({ pageName }) => {
  const [conditionData, setConditionData] = useState(null);

  const handleSuccess = useSuccessHandler();

  const handleError = useErrorHandler();

  const [showInputField2, setShowInputField2] = useState(false);

  const [showColumn2Inputs, setShowColumn2Inputs] = useState(false);

  const [showColumn3Input, setShowColumn3Input] = useState(false);

  const initialValues = {
    prefix: conditionData?.prefix || "",
    website: conditionData?.website || "",
    runningText: conditionData?.runningText || "",
    lineId: conditionData?.lineId || "",
    lineToken: conditionData?.lineToken || "",
    lineTokenWithdraw: conditionData?.lineTokenWithdraw || "",
    telegramToken: conditionData?.telegramToken || "",
    telegramDeposit: conditionData?.telegramDeposit || "",
    telegramWithdraw: conditionData?.telegramWithdraw || "",
    config: conditionData?.config || {
      allowOutstandingBet: "false",
      approveWithdrawAuto: false,
      limitWithDraw: false,
      winLoseRefund: true,
      winLoseRefundMax: "0",
      onOffWebsite: true,
      minWithdrawCredit: "1",
      forceWithdrawAll: false,
      countWithDraw: "1000",
      winLoseRefundTurn: "0",
      winLoseRefundType: "WEEKLY",
      checkBonusWithdraw: false,
      maxAutoWithdraw: 20,
      winLoseRefundRate: 5,
      approveWithdrawAutoNew: true,
    },
  };

  const handleSubmit = (values) => {
    const req = {
      ...conditionData,
      ...values,
    };
    updateWebSetting(req)
      .then((response) => {
        if (response.status === 200) {
          handleSuccess(response.data.message);
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchWebSetting()
      .then((response) => {
        setConditionData(response.data.data);
        setShowInputField2(
          response.data.data?.config?.approveWithdrawAutoNew || false,
        );
        setShowColumn2Inputs(
          response.data.data?.config?.winLoseRefund || false,
        );
        setShowColumn3Input(response.data.data?.config?.limitWithDraw || false);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
        </AnimatedFlex>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <Flex direction="column">
                <Stack direction="column" spacing="24px" w="100%">
                  <AnimatedFlex duration={0.4} delay={0.4}>
                    <Card
                      w={{ sm: "100%" }}
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                    >
                      <Element id="info" name="info">
                        <CardBody>
                          <Grid
                            templateColumns={{
                              base: "1fr",
                              sm: "repeat(2, 1fr)",
                              md: "repeat(3, 1fr)",
                            }}
                            gap="24px"
                            w="100%"
                          >
                            <GridItem colSpan={{ base: 1, sm: 2, md: 1 }}>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  Prefix
                                </FormLabel>
                                <Input
                                  borderRadius="4px"
                                  placeholder="eg. Michael"
                                  fontSize="sm"
                                  value={values.prefix}
                                  readOnly
                                  backgroundColor="gray.800"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  ลิ้งค์เว็บไซต์
                                </FormLabel>
                                <Input
                                  borderRadius="4px"
                                  placeholder="eg. Jackson"
                                  fontSize="sm"
                                  value={values.website}
                                  onChange={handleChange}
                                  name="website"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  ไลน์ OA
                                </FormLabel>
                                <Input
                                  borderRadius="4px"
                                  placeholder="eg. Jackson"
                                  fontSize="sm"
                                  value={values.lineId}
                                  onChange={handleChange}
                                  name="lineId"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  อักษรวิ่งหน้าเว็บ
                                </FormLabel>
                                <Input
                                  borderRadius="4px"
                                  placeholder="eg. Jackson"
                                  fontSize="sm"
                                  value={values.runningText}
                                  onChange={handleChange}
                                  name="runningText"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  Line แจ้งเตือนฝาก{" "}
                                  <Box
                                    as="span"
                                    color="green.500"
                                    fontWeight="normal"
                                  >
                                    (ใส่ Token)
                                  </Box>
                                </FormLabel>
                                <Input
                                  borderRadius="4px"
                                  placeholder="eg. Jackson"
                                  fontSize="sm"
                                  value={values.lineToken}
                                  onChange={handleChange}
                                  name="lineToken"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  Line แจ้งเตือนถอน{" "}
                                  <Box
                                    as="span"
                                    color="green.500"
                                    fontWeight="normal"
                                  >
                                    (ใส่ Token)
                                  </Box>
                                </FormLabel>
                                <Input
                                  borderRadius="4px"
                                  placeholder="eg. Jackson"
                                  fontSize="sm"
                                  value={values.lineTokenWithdraw}
                                  onChange={handleChange}
                                  name="lineTokenWithdraw"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 1, sm: 2, md: 1 }}>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  Telegram Token
                                </FormLabel>
                                <Input
                                  borderRadius="4px"
                                  placeholder="eg. Michael"
                                  fontSize="sm"
                                  value={values.telegramToken}
                                  onChange={handleChange}
                                  name="telegramToken"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 1, sm: 2, md: 1 }}>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  ID กลุ่ม Telegram แจ้งเตือนฝาก
                                </FormLabel>
                                <Input
                                  borderRadius="4px"
                                  placeholder="eg. Jackson"
                                  fontSize="sm"
                                  value={values.telegramDeposit}
                                  onChange={handleChange}
                                  name="telegramDeposit"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 1, sm: 2, md: 1 }}>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  ID กลุ่ม Telegram แจ้งเตือนถอน
                                </FormLabel>
                                <Input
                                  borderRadius="4px"
                                  placeholder="eg. Jackson"
                                  fontSize="sm"
                                  value={values.telegramWithdraw}
                                  onChange={handleChange}
                                  name="telegramWithdraw"
                                />
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </CardBody>
                      </Element>
                    </Card>
                  </AnimatedFlex>
                  <AnimatedFlex duration={0.4} delay={0.6}>
                    <Card
                      w={{ sm: "100%" }}
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                    >
                      <Element id="config" name="config">
                        <CardBody>
                          <SimpleGrid
                            columns={{ base: 1, md: 2, lg: 3 }}
                            spacing={6}
                            width="100%"
                          >
                            <Box>
                              <FormControl mb={4}>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  เปิด/ปิด เว็บไซต์
                                </FormLabel>
                                <Switch
                                  isChecked={values.config.onOffWebsite}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "config.onOffWebsite",
                                      e.target.checked,
                                    )
                                  }
                                />
                              </FormControl>

                              <FormControl mb={4}>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  จำนวนเครดิตขั้นต่ำที่สามารถถอนได้
                                </FormLabel>
                                <NumberInput
                                  value={values.config.minWithdrawCredit}
                                  onChange={(valueString) =>
                                    setFieldValue(
                                      "config.minWithdrawCredit",
                                      valueString,
                                    )
                                  }
                                  min={0}
                                >
                                  <NumberInputField
                                    placeholder="0.00"
                                    fontSize="sm"
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mt="10px"
                                >
                                  อนุมัติรายการถอนแบบออโต้
                                </FormLabel>
                                <Switch
                                  isChecked={values.config.approveWithdrawAuto}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "config.approveWithdrawAuto",
                                      e.target.checked,
                                    );
                                    setShowInputField2(e.target.checked);
                                  }}
                                />
                              </FormControl>

                              <FormControl mb={4}>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  บังคับถอนทั้งหมด
                                </FormLabel>
                                <Switch
                                  isChecked={values.config.forceWithdrawAll}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "config.forceWithdrawAll",
                                      e.target.checked,
                                    )
                                  }
                                />
                              </FormControl>

                              <FormControl mb={4}>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  ต้องอนุมัติหากรับโปรโมชั่น
                                </FormLabel>
                                <Switch
                                  isChecked={values.config.checkBonusWithdraw}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "config.checkBonusWithdraw",
                                      e.target.checked,
                                    )
                                  }
                                />
                              </FormControl>

                              {showInputField2 && (
                                <FormControl mb={4}>
                                  <FormLabel
                                    fontWeight="semibold"
                                    fontSize="xs"
                                    mb="10px"
                                  >
                                    จำนวนเงินมากที่สุดที่จะไม่ทำการอนุมัติออโต้
                                  </FormLabel>
                                  <NumberInput
                                    value={values.config.maxAutoWithdraw}
                                    onChange={(valueString) =>
                                      setFieldValue(
                                        "config.maxAutoWithdraw",
                                        valueString,
                                      )
                                    }
                                    min={0}
                                  >
                                    <NumberInputField
                                      placeholder="0.00"
                                      fontSize="sm"
                                    />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                </FormControl>
                              )}
                            </Box>

                            <Box>
                              <FormControl mb={4}>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  รอบการคืนยอดเสีย
                                </FormLabel>
                                <Switch
                                  isChecked={values.config.winLoseRefund}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "config.winLoseRefund",
                                      e.target.checked,
                                    );
                                    setShowColumn2Inputs(e.target.checked);
                                  }}
                                />
                              </FormControl>
                              {showColumn2Inputs && (
                                <>
                                  <FormControl mb={4}>
                                    <FormLabel
                                      fontWeight="semibold"
                                      fontSize="xs"
                                      mb="10px"
                                    >
                                      ประเภทการคืนยอดเสีย
                                    </FormLabel>
                                    <Select
                                      fontSize="sm"
                                      value={values.config.winLoseRefundType}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "config.winLoseRefundType",
                                          e.target.value,
                                        )
                                      }
                                    >
                                      {TYPEREFUND.map((type, id) => (
                                        <option key={id} value={type.typeCode}>
                                          {type.typeName}
                                        </option>
                                      ))}
                                    </Select>
                                  </FormControl>

                                  <FormControl mb={4}>
                                    <FormLabel
                                      fontWeight="semibold"
                                      fontSize="xs"
                                      mb="10px"
                                    >
                                      เปอร์เซ็นการคืนยอดเสีย{" "}
                                      <Box
                                        as="span"
                                        color="green.500"
                                        fontWeight="normal"
                                      >
                                        (%)
                                      </Box>
                                    </FormLabel>
                                    <NumberInput
                                      value={values.config.winLoseRefundRate}
                                      onChange={(valueString) =>
                                        setFieldValue(
                                          "config.winLoseRefundRate",
                                          valueString,
                                        )
                                      }
                                      min={0}
                                    >
                                      <NumberInputField
                                        placeholder="5%"
                                        fontSize="sm"
                                      />
                                      <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                      </NumberInputStepper>
                                    </NumberInput>
                                  </FormControl>

                                  <FormControl mb={4}>
                                    <FormLabel
                                      fontWeight="semibold"
                                      fontSize="xs"
                                      mb="10px"
                                    >
                                      เทิร์นการคืนยอดเสีย{" "}
                                      <Box
                                        as="span"
                                        color="green.500"
                                        fontWeight="normal"
                                      >
                                        (คูณกี่เท่า)
                                      </Box>
                                    </FormLabel>
                                    <NumberInput
                                      value={values.config.winLoseRefundTurn}
                                      onChange={(valueString) =>
                                        setFieldValue(
                                          "config.winLoseRefundTurn",
                                          valueString,
                                        )
                                      }
                                      min={0}
                                    >
                                      <NumberInputField
                                        placeholder="5"
                                        fontSize="sm"
                                      />
                                      <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                      </NumberInputStepper>
                                    </NumberInput>
                                  </FormControl>
                                  <FormControl>
                                    <FormLabel
                                      fontWeight="semibold"
                                      fontSize="xs"
                                      mb="10px"
                                    >
                                      การได้คืนยอดเสียสูงสุด{" "}
                                      <Box
                                        as="span"
                                        color="green.500"
                                        fontWeight="normal"
                                      >
                                        (บาท)
                                      </Box>
                                    </FormLabel>
                                    <NumberInput
                                      value={values.config.winLoseRefundMax}
                                      onChange={(valueString) =>
                                        setFieldValue(
                                          "config.winLoseRefundMax",
                                          valueString,
                                        )
                                      }
                                      min={0}
                                    >
                                      <NumberInputField
                                        placeholder="0.00"
                                        fontSize="sm"
                                      />
                                      <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                      </NumberInputStepper>
                                    </NumberInput>
                                  </FormControl>
                                </>
                              )}
                            </Box>
                            <Box>
                              <FormControl mb={4}>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  จำกัดจำนวนครั้งการถอนต่อวัน
                                </FormLabel>
                                <Switch
                                  isChecked={values.config.limitWithDraw}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "config.limitWithDraw",
                                      e.target.checked,
                                    );
                                    setShowColumn3Input(e.target.checked);
                                  }}
                                />
                              </FormControl>
                              {showColumn3Input && (
                                <FormControl mb={4}>
                                  <FormLabel
                                    fontWeight="semibold"
                                    fontSize="xs"
                                    mb="10px"
                                  >
                                    ถอนได้กี่ครั้งต่อวัน{" "}
                                    <Box
                                      as="span"
                                      color="green.500"
                                      fontWeight="normal"
                                    >
                                      (ตัดรอบ 23.00 ของทุกวัน)
                                    </Box>
                                  </FormLabel>
                                  <NumberInput
                                    value={values.config.countWithDraw}
                                    onChange={(valueString) =>
                                      setFieldValue(
                                        "config.countWithDraw",
                                        valueString,
                                      )
                                    }
                                    min={0}
                                  >
                                    <NumberInputField
                                      placeholder="10"
                                      fontSize="sm"
                                    />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                </FormControl>
                              )}
                            </Box>
                          </SimpleGrid>
                        </CardBody>
                      </Element>
                    </Card>
                  </AnimatedFlex>
                  <AnimatedFlex
                    justify="flex-end"
                    pt="15px"
                    duration={0.4}
                    delay={0.8}
                  >
                    <Button
                      backgroundColor="green.500"
                      color={"#000000"}
                      width={{ base: "100%", md: "auto" }}
                      minW={{ base: "none", md: "280px" }}
                      borderRadius="6px"
                      type="submit"
                    >
                      บันทึกข้อมูล
                    </Button>
                  </AnimatedFlex>
                </Stack>
              </Flex>
            </Form>
          )}
        </Formik>
      </Stack>
    </Flex>
  );
};

export default ConditionSetting;
