import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Textarea,
} from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";

import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import { Element } from "react-scroll";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import {
  fetchSeoSetting,
  updateSeoSetting,
} from "services/website-setting/seoSettingService";

const SeoSetting = ({ pageName }) => {
  const [seoDetail, setSeoDetail] = useState({});

  const handleSuccess = useSuccessHandler();

  const handleError = useErrorHandler();

  const [metaKeyword, setMetaKeyword] = useState([]);

  const keyPress = (e, setFieldValue) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      const newKeywords = [
        ...metaKeyword,
        {
          name: e.target.value,
          id:
            metaKeyword.length === 0
              ? 1
              : metaKeyword[metaKeyword.length - 1].id + 1,
        },
      ];
      setMetaKeyword(newKeywords);
      setFieldValue("metaKeyword", newKeywords);
      e.target.value = "";
    }
  };

  const handleSubmit = (values) => {
    let formattedKeywords = "";
    if (Array.isArray(values.metaKeyword)) {
      formattedKeywords = values.metaKeyword
        .map((keyword) => keyword.name)
        .join(" ");
    } else {
      formattedKeywords = values.metaKeyword;
    }

    const formattedValues = { ...values, metaKeyword: formattedKeywords };

    updateSeoSetting(formattedValues)
      .then((response) => {
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchSeoSetting()
      .then((response) => {
        const data = response.data.data;
        setSeoDetail(data);

        if (data.metaKeyword) {
          const splitKeywords = data.metaKeyword
            .split(" ")
            .map((keyword, index) => ({
              name: keyword.trim(),
              id: index + 1,
            }));
          setMetaKeyword(splitKeywords);
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
        </AnimatedFlex>
        <Formik
          initialValues={{
            ...seoDetail,
            pageTitle: seoDetail?.pageTitle || "",
            pageUrl: seoDetail?.pageUrl || "",
            metaTitle: seoDetail?.metaTitle || "",
            googleAnalytic: seoDetail?.googleAnalytic || "",
            metaDescription: seoDetail?.metaDescription || "",
            metaKeyword: seoDetail?.metaKeyword || [],
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ setFieldValue }) => (
            <Form>
              <Flex direction="column">
                <AnimatedFlex duration={0.4} delay={0.6}>
                  <Card
                    w={{ sm: "100%" }}
                    alignSelf="flex-end"
                    justifySelf="flex-end"
                  >
                    <Element id="info" name="info">
                      <CardBody>
                        <Grid
                          templateColumns={{
                            base: "1fr",
                            md: "repeat(2, 1fr)",
                          }}
                          gap="24px"
                          w="100%"
                        >
                          <GridItem>
                            <FormControl>
                              <FormLabel
                                fontWeight="semibold"
                                fontSize="xs"
                                mb="10px"
                              >
                                Page Title
                              </FormLabel>
                              <Field
                                as={Input}
                                name="pageTitle"
                                borderRadius="4px"
                                placeholder="eg. Jackson"
                                fontSize="sm"
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel
                                fontWeight="semibold"
                                fontSize="xs"
                                mb="10px"
                              >
                                Page Url
                              </FormLabel>
                              <Field
                                as={Input}
                                name="pageUrl"
                                borderRadius="4px"
                                placeholder="eg. Jackson"
                                fontSize="sm"
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel
                                fontWeight="semibold"
                                fontSize="xs"
                                mb="10px"
                              >
                                Meta Title
                              </FormLabel>
                              <Field
                                as={Input}
                                name="metaTitle"
                                borderRadius="4px"
                                placeholder="eg. Jackson"
                                fontSize="sm"
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel
                                fontWeight="semibold"
                                fontSize="xs"
                                mb="10px"
                              >
                                Google Analytics
                              </FormLabel>
                              <Field
                                as={Input}
                                name="googleAnalytic"
                                borderRadius="4px"
                                placeholder="eg. Jackson"
                                fontSize="sm"
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel
                                fontWeight="bold"
                                fontSize="xs"
                                mb="10px"
                              >
                                Keywords
                              </FormLabel>
                              <Flex
                                direction="row"
                                p="12px"
                                wrap="wrap"
                                border="1px solid rgba(255,255,255,0.3)"
                                borderRadius="4px"
                                _focus={{ borderColor: "teal.300" }}
                                minH="150px"
                                cursor="text"
                              >
                                {metaKeyword.map((keyword) => {
                                  return (
                                    <Tag
                                      fontSize="sm"
                                      h="25px"
                                      mb="6px"
                                      me="6px"
                                      key={keyword.id}
                                      borderRadius="12px"
                                      // variant="outline"
                                      border="1px"
                                      backgroundColor={"transparent"}
                                      borderColor={"green.500"}
                                      color={"green.500"}
                                    >
                                      <TagLabel w="100%">
                                        {keyword.name}
                                      </TagLabel>
                                      <TagCloseButton
                                        justifySelf="flex-end"
                                        onClick={() => {
                                          const newKeywords =
                                            metaKeyword.filter(
                                              (element) =>
                                                element.id !== keyword.id,
                                            );
                                          setMetaKeyword(newKeywords);
                                          setFieldValue(
                                            "metaKeyword",
                                            newKeywords,
                                          );
                                        }}
                                      />
                                    </Tag>
                                  );
                                })}
                                <Input
                                  border="none"
                                  _focus="none"
                                  p="0px"
                                  onKeyDown={(e) => keyPress(e, setFieldValue)}
                                  fontSize="sm"
                                />
                              </Flex>
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel
                                fontWeight="semibold"
                                fontSize="xs"
                                mb="10px"
                              >
                                Description
                              </FormLabel>
                              <Field
                                name="metaDescription"
                                as={Textarea}
                                borderRadius="4px"
                                placeholder="eg. Michael"
                                fontSize="sm"
                                minHeight="150px"
                              />
                            </FormControl>
                          </GridItem>
                        </Grid>
                      </CardBody>
                    </Element>
                  </Card>
                </AnimatedFlex>
                <AnimatedFlex
                  justify="flex-end"
                  pt="15px"
                  duration={0.4}
                  delay={0.8}
                >
                  <Button
                    type="submit"
                    backgroundColor="green.500"
                    color={"#000000"}
                    width={{ base: "100%", md: "auto" }}
                    minW={{ base: "none", md: "280px" }}
                    borderRadius="6px"
                  >
                    บันทึกข้อมูล
                  </Button>
                </AnimatedFlex>
              </Flex>
            </Form>
          )}
        </Formik>
      </Stack>
    </Flex>
  );
};

export default SeoSetting;
