import {
  Box,
  Button,
  Flex,
  Spacer,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { memberDepositWithdrawColumnsData } from "variables/columns/member-report/memberDepositWithdrawColumnsData";
import moment from "moment";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import { QueryClient, QueryClientProvider } from "react-query";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { fetchMemberTransactionReport } from "services/member-report/memberTransactionReportService";
import { getReferenceDates } from "../../../utils/DateformatUtils";
import { mapJsonToTableData } from "../../../utils/CommonUtils";
import { useCSVDownloader } from "react-papaparse";

const MemberTransactionReport = ({ pageName }) => {
  const queryClient = new QueryClient();

  const { dateNow, dateDayCheck } = getReferenceDates();

  const [search, setSearch] = useState({});
  const [selectedDates, setSelectedDates] = useState([dateDayCheck, dateNow]);
  const [isLoading] = useState(false);
  const [downloadableData, setDownloadableData] = useState([]);
  const { CSVDownloader, Type } = useCSVDownloader();

  useEffect(() => {
    setSearch({
      listDateFrom: moment(dateNow).format("yyyy-MM-DD HH:mm:ss"),
      listDateTo: moment(dateDayCheck).format("yyyy-MM-DD HH:mm:ss"),
    });
  }, []);

  const fetchData = async (pageIndex, pageSize, search) => {
    const response = await fetchMemberTransactionReport(
      pageIndex,
      pageSize,
      search,
    );
    const records = await fetchMemberTransactionReport(pageIndex, 1000, search);
    const formattedRecords = mapJsonToTableData(
      records.results,
      memberDepositWithdrawColumnsData(),
    );

    setDownloadableData(formattedRecords);
    return response;
  };

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Box>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={(v) => {
                setSelectedDates(v);
                if (v.length == 2) {
                  setSearch({
                    ...search,
                    listDateFrom: moment(v[0]).format("yyyy-MM-DD HH:mm:ss"),
                    listDateTo: moment(v[1]).format("yyyy-MM-DD HH:mm:ss"),
                  });
                } else {
                  setSearch({
                    ...search,
                    listDateFrom: moment(v[0]).format("yyyy-MM-DD HH:mm:ss"),
                  });
                }
              }}
            />
          </Box>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" mb="6px">
                  รายงานฝากถอนสมาชิก
                </Text>
              </Flex>
              <Spacer />
              <Button
                borderColor={"green.500"}
                borderWidth="1px"
                ml={2}
                color="green.500"
                backgroundColor="transparent"
              >
                <CSVDownloader
                  filename={"รายงานฝากถอนสมาชิก"}
                  data={downloadableData}
                  type={Type.Button}
                >
                  <Text fontWeight="bold">Export as CSV</Text>
                </CSVDownloader>
              </Button>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <Flex direction="column" align="flex-end" mb="24px" w="100%">
                  <Box width="100%">
                    <QueryClientProvider client={queryClient}>
                      <SearchTableRemote
                        columnsData={memberDepositWithdrawColumnsData()}
                        fetchData={fetchData}
                        search={search}
                        setSearch={setSearch}
                      />
                    </QueryClientProvider>
                  </Box>
                </Flex>
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default MemberTransactionReport;
