import { Badge, Flex, IconButton, Text, useClipboard } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { HiOutlineSquare2Stack } from "react-icons/hi2";

export const memberDepositWithdrawColumnsData = () => {
  return [
    {
      Header: "ระดับ",
      accessor: "memberLevelName",
      Cell: ({ cell }) => (
        <Flex align="center" justify="center">
          <Badge
            colorScheme={
              cell.value === "Platinum"
                ? "blue"
                : cell.value === "Silver"
                ? "gray"
                : cell.value === "Gold"
                ? "yellow"
                : "default"
            }
            w="65px"
            h="28px"
            borderRadius="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {cell.value ? cell.value : "-"}
          </Badge>
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: "ยูสเซอร์",
      accessor: "username",
      Cell: ({ row }) => {
        const history = useHistory();
        const { onCopy } = useClipboard(row.original.username);

        return (
          <Flex align="center">
            <IconButton
              icon={<HiOutlineSquare2Stack />}
              onClick={onCopy}
              aria-label="Copy link"
              variant="outline"
              size="sm"
              border="none"
              mr={2}
            />
            <Text
              color={row.original.memberTagsColor}
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
              onClick={() =>
                history.push(`/admin/member/${row.original.username}`)
              }
            >
              {row.original.username}
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: "จำนวนครั้งที่เติม",
      accessor: "depositCount",
    },
    {
      Header: "เติมทั้งหมด",
      accessor: "deposit",
    },
    {
      Header: "จำนวนครั้งที่ถอน",
      accessor: "withdrawCount",
    },
    {
      Header: "ถอนทั้งหมด",
      accessor: "withdraw",
    },
    {
      Header: "กำไรขาดทุน ไม่รวมโบนัส",
      accessor: "profitLoss",
      Cell: ({ value }) => (
        <Text color={value < 0 ? "red.500" : "green.500"}>{value}</Text>
      ),
    },
  ];
};
