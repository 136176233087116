import { Box, Flex, Spacer, Spinner, Stack, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import React, { useEffect, useState } from "react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import { fetchStaffLoginReport } from "services/website-report/staffLoginReportService";

import { staffLoginHistoryColumnsData } from "variables/columns/website-report/staffLoginHistoryColumnsData";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import moment from "moment/moment";
import { QueryClient, QueryClientProvider } from "react-query";
import SearchTableRemote from "components/Tables/SearchTableRemote";

const StaffLoginReport = ({ pageName }) => {
  const [selectedDates, setSelectedDates] = useState(["", new Date()]);
  const queryClient = new QueryClient();

  const [isLoading, setIsLoading] = useState(true);

  const [search, setSearch] = useState({});

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Box>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={(v) => {
                setSelectedDates(v);
                if (v.length == 2) {
                  setSearch({
                    ...search,
                    listDateFrom: moment(selectedDates[0]).format("DD/MM/yyyy"),
                    listDateTo: moment(selectedDates[1]).format("DD/MM/yyyy"),
                  });
                } else {
                  setSearch({
                    ...search,
                    listDateFrom: moment(selectedDates[0]).format("DD/MM/yyyy"),
                  });
                }
              }}
            />
          </Box>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  ประวัติการพนักงานเข้าสู่ระบบ
                </Text>
              </Flex>
              <Spacer />
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <QueryClientProvider client={queryClient}>
                  <SearchTableRemote
                    columnsData={staffLoginHistoryColumnsData()}
                    fetchData={fetchStaffLoginReport}
                    search={search}
                    setSearch={setSearch}
                  />
                </QueryClientProvider>
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default StaffLoginReport;
